.PromoDetail {
    min-width: 1120px;
    height: 1346px;
    .ant-modal-title {
        text-align: center;
        font: normal normal bold 20px/27px Microsoft YaHei;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }
    .PromoDetail-info {
        padding-top: 24px;
        min-height: 305px;
    }
    .PromoDetail-submitBtn {
        width: 100%;
        display: flex;
        justify-content: center;
        button {
            width: 345px;
            height: 40px;
            /* UI Properties */
            background: #00a6ff 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            border: 0;
            text-align: center;
            font: normal normal 600 14px/19px Microsoft YaHei;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            cursor: pointer;
        }
    }
}

// 申請優惠彈窗

.commonModal.ant-modal-confirm {
    width: 400px !important;
    .ant-modal-close {
        display: none;
    }
    .ant-modal-body {
        padding: 0;
    }
    .ant-modal-confirm-body {
        i {
            display: none;
        }
    }
    .ant-modal-confirm-title {
        height: 60px;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        padding-top: 14.5px;
        background: #00a6ff 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
    }
    .note {
        height: 20px;
        text-align: center;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
    }
    .ant-modal-confirm-btns {
        padding: 0 30px 30px 30px;
    }
}
