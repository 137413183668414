// 登入(歡迎您)跳窗
.modal-pubilc {
    &.upload-file-modal-auto-popup-show {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0 24px 24px 24px
            }
        }
    }

    text-align: center;

    .ant-tabs {
        overflow: visible;
    }

    // &.ant-modal {
    //     height: 386px;
    // }

    .ant-modal-header {
        background: #00a5fd;
        border-radius: 20px 20px 0 0;

        .ant-modal-title {
            color: #fff;
            font-size: 20px;
            height: 27px;
        }
    }

    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-body {
            padding: 32px 50px 30px 50px;

            .ant-input-prefix {
                img {
                    width: 20px;
                    height: 20px;
                }
            }

            .ant-input-affix-wrapper .ant-input:not(:first-child) {
                padding-left: 38px;
            }

            .IputBox {
                margin-bottom: 16px;
            }

            .ant-form {
                &.ant-form-horizontal {
                    .ant-form-item-control.has-error {
                        display: flex;
                        flex-direction: column;

                        .ant-form-explain {
                            order: -1;
                            margin-top: 0px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .ant-btn-primary {
                font-weight: 700;
            }

            .login-error {
                margin-bottom: 10px;
                font-size: 12px;
                text-align: left;
                letter-spacing: 0px;
                color: #eb2121;
                background: #fee5e5 0% 0% no-repeat padding-box;
                border-radius: 8px;
                opacity: 1;
                padding: 10px;
            }
        }

        button:disabled {
            background-color: #ccc;
            border-color: #ccc;
            color: #fff;
        }
    }

    &.login-and-registration {
        .ant-modal-header {
            padding: 19px 171px 14px 169px;
        }

        .ant-tabs-nav-wrap {
            width: 299px;
            height: 40px;
        }

        .ant-modal-content {
            border-radius: 20px;

            .ant-modal-body {
                .ant-form-item {
                    margin-bottom: 0px;
                }

                .IputBox {
                    margin-bottom: 10px;
                }

                .ant-form {
                    &.ant-form-horizontal {
                        .ant-form-item-control.has-error {
                            display: flex;
                            flex-direction: column;

                            .ant-form-explain {
                                order: -1;
                                margin-top: 0px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .Forget-pwd-tabs {
        // padding: 10px 20px;

        .ant-tabs-bar {
            margin-bottom: 32px;
        }

        .ant-tabs-ink-bar {
            height: 98%;
            z-index: -1;
            border-radius: 50px;
        }

        .ant-tabs-tabpane {
            label {
                font-size: 12px;
                text-align: left;
            }
        }

        .IputBox {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .invalid-input-error {
            margin-bottom: 10px;
            font-size: 12px;
            text-align: left;
            letter-spacing: 0px;
            color: #eb2121;
            background: #fee5e5 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            padding: 10px;
        }

        .invalid-input-box-error {
            border-color: #ff4d4f;
        }

        .error {
            margin-top: 10px;
            font-size: 12px;
            text-align: left;
            letter-spacing: 0px;
            color: #eb2121;
            background: #fee5e5 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            padding: 10px;
        }

        label {
            display: block;
            margin: 0px 0px 5px 5px;
        }

        &.tabs-modal-pubilc {
            .ant-tabs-content {
                .ant-tabs-tabpane {
                    .IputBox {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        margin-bottom: 32px;

                        &.forgetuser-input {
                            gap: 0;

                            button {
                                background-color: #00a6ff;
                            }
                        }
                    }
                }
            }
        }
    }

    .IputBox {
        .registerPhone.ant-input-affix-wrapper {
            .ant-input:not(:first-child) {
                padding-left: 62px;
            }
        }
    }

    .IputBox.fix-not-required-text {
        position: relative;
        .not-required-text {
            position: absolute;
            right: 20px;
            bottom: 10px;
            letter-spacing: 0px;
            color: #999999;
            opacity: 1;
            font-size: 14px;
            font-family: MicrosoftYaHei;
        }
    }

    // 切換登入/註冊Tab
    .tabs-modal-pubilc {
        .ant-tabs-bar {
            background-color: #7676801f;
            border-radius: 50px;
            margin-bottom: 30px;

            .ant-tabs-nav-container {
                .ant-tabs-nav-scroll {
                    width: 100%;

                    .ant-tabs-nav {
                        width: 100%;
                        z-index: 10;

                        // tab選項box
                        > div:not(:last-child) {
                            width: 100%;
                            display: flex;

                            // 單個tab
                            > div {
                                width: 100%;
                                margin: 0;
                            }

                            .ant-tabs-tab-active {
                                color: white !important;
                            }
                        }

                        .ant-tabs-ink-bar {
                            height: 98%;
                            z-index: -1;
                            border-radius: 50px;
                        }
                    }
                }
            }
        }
    }

    .link-box {
        text-align: right;
        height: 16px;
        margin-bottom: 30px;

        button:first-child {
            display: flex;
            flex-direction: column;
            justify-content: flex-start; //css
            padding: 0;
            height: 16px;
            margin-left: auto;
        }

        span {
            font-size: 12px;
        }
    }

    .protocol-box {
        width: 318px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        margin-left: -9px;
        .protocol {
            font-size: 12px;
            transform: scale(0.85);
            // font-family: "Microsoft YaHei", sans-serif;
            text-align: center;
            margin-top: 20px;
        }
    }

    &.exceed-modal {
        .ant-modal-body {
            background-color: white !important;
            .otp-cs-tip {
                text-align: center;
            }
        }
    }
    &.OTP-modal {
        // otp 驗證彈窗
        .ant-modal-content {
            font-family: "Microsoft YaHei";
            background-color: white;
        }
        .ant-modal-body {
            padding: 30px 32px 24px 32px;
            background-color: white;
            border-radius: 20px;
            .otp-modal-title {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                margin: 20px auto;
                color: #222222;
            }

            .otp-warn {
                display: block;
                margin: 0 auto;
            }

            .otp-modal-banner {
                margin-left: -32px;
                margin-top: -30px;
            }

            .otp-modal-description {
                //text-align: justify;
                margin: 20px 0;
                padding: 10px;
                background-color: white;
                border-radius: 10px;
                font-size: 14px;

                h4 {
                    font-weight: 600;
                    margin: 10px 0;
                }

                .otp-cs {
                    color: #1c8eff;
                    cursor: pointer;
                }
            }

            .verify-icon-wrap {
                width: 70px;
                height: 70px;
                margin: 0 auto;
                display: block;
                background: white;
                border-radius: 10px;
                position: relative;
                cursor: pointer;
                transition: 0.5s;

                .verify-icon {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &.red-email,
                &.red-phone,
                &.red-cs {
                    background: lighten(#00a6ff, 20%);
                }

                &:checked {
                    background: darken(#00a6ff, 5%);
                }
            }

            .verify-icon-content {
                text-align: center;
                padding-top: 10px;
            }
            .OtpList {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .otpBox {
                background-color: white;
                padding: 10px;
                border-radius: 8px;
                text-align: center;
            }
            .otpBtn {
                margin: 30px auto 10px;
                color: #00a6ff;
                background: #fff;
                border: 1px solid #00a6ff;
                border-radius: 8px;
                &.readMore {
                    color: #fff;
                    background: #00a6ff;
                    margin-top: 10px;
                }
                &.securityAnnouncement {
                    width: 332px;
                    margin-left: 54px;
                    margin-right: 54px;
                }
            }

            .verification-form-wrap {
                .timeTip {
                    width: 100%;
                    text-align: center;
                }

                .error-tip {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    text-align: left;
                    color: #f00;
                    font-size: 12px;
                }

                .otpPwdModal-Item {
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    i {
                        width: 60px;
                        height: 60px;
                        display: inline-block;

                        img {
                            width: 100%;
                            width: 100%;
                        }
                    }

                    .text1 {
                        font-size: 18px;
                        color: #000;
                        font-weight: bold;
                        padding: 20px 0 10px;
                    }

                    .text2 {
                        font-size: 14px;
                        color: #222222;
                    }
                }

                .text-tip span {
                    color: #1c8eff;
                    cursor: pointer;
                }

                .tlc-input-disabled {
                    .icon-text-eyes {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }

                    .icon-password-eyes {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }
                }

                .Item-bankName.inactive .ant-form-item-control {
                    box-shadow: 0;
                    width: 100%;
                }

                .Item-bankName.active .ant-form-item-control {
                    box-shadow: 0 0 1px 1px #0000001a, 0 1px 2px 0 #00000014,
                        0 2px 8px 2px #0000000d;
                    border-bottom: none;
                    border-radius: 8px 8px 0 0;
                }

                .bankNamewrap .ant-input:not(:first-child) {
                    padding-left: 13px;
                }

                .bankNamewrap.have .ant-input:not(:first-child) {
                    padding-left: 38px;
                }

                .bankNamewrap.active .ant-input {
                    border-right: 0;
                    border-bottom: 0;
                    border-left: 0;
                    border-radius: 0px;
                    border: none;
                    background: transparent;
                }

                .bankNameSelect .ant-select-selection {
                    border: 1px solid #cccccc;
                    box-shadow: none;
                    border-radius: 4px;
                    height: 35px;
                    width: 96%;
                    margin: 0 2%;

                    .searchwrap {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        background: none;
                        display: flex;
                        align-items: center;
                    }

                    .ant-select-selection__rendered {
                        line-height: 35px;
                    }
                }

                .Item-bankName .notfind {
                    width: 100%;
                    text-align: center;

                    img {
                        width: 80px;
                        height: 80px;
                    }

                    p {
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }

        &.addBankModal {
            .ant-modal-body {
                padding: 0 30px 30px 30px;

                .ant-row {
                    &.ant-form-item:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .forAddBankCard {
                .ant-select {
                    .ant-select-selection {
                        display: flex;
                        align-items: center;
                        height: 100%;

                        .ant-select-selection__rendered {
                            margin-left: 20px;
                        }
                    }
                }
                .ant-select-disabled .ant-select-selection .ant-select-arrow {
                    display: block;
                }
            }
        }
    }

    &.CTC-INVOICE-AUT {
        .ant-col-12 {
            text-align: center;
            border: 1px solid #00a6ff;
            border-radius: 6px;
            width: 45%;
            height: 40px;
            line-height: 40px;
            margin: 6% 2.5% 0;
            cursor: pointer;

            &:first-child {
                color: #00a6ff;
            }

            &:last-child {
                color: #fff;
                background-color: #00a6ff;
            }
        }
    }
}

.Spin_modal {
    top: 35%;

    .ant-modal-header {
        display: none;
    }

    .ant-modal-body {
        text-align: center;
        height: 95px;

        .ant-spin {
            padding-top: 20%;
            border-radius: 8px;
        }
    }
}

.ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
}

.ant-message {
    z-index: 10000 !important;
}

//银行卡资讯
.bankDetailsModal .ant-modal-body {
    .ant-row {
        margin-left: 0px !important;
        margin-right: 0px !important;

        .ant-col-12 {
            &:first-child {
                padding-left: 0px !important;
                padding-right: 15px !important;

                div {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid #1a1a1a1a;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #666666;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }

            &:last-child {
                padding-left: 15px !important;
                padding-right: 0px !important;

                label {
                    color: #999999;
                    font-size: 12px;
                }

                .text1 {
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                .text2 {
                    font-size: 12px;
                    height: 20px;
                    line-height: 20px;
                }

                .text3 {
                    font-size: 12px;
                    color: #999999;
                    border-bottom: 1px solid #1a1a1a1a;
                    height: 20px;
                    line-height: 20px;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }

                .text4,
                .text5 {
                    width: 100%;
                    color: #999999;
                    height: 40px;
                    line-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #1a1a1a1a;
                }

                .text6 {
                    font-size: 12px;
                    background: #fff5bf;
                    color: #a4893b;
                    padding: 0.5rem;
                    border-radius: 10px;

                    span {
                        color: #f00;
                    }
                }

                .startDate-Item {
                    position: relative;

                    .clearItem {
                        position: absolute;
                        right: 10px;
                        top: -50px;
                        color: #1c8eff;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.bankAccountModals {
    .ant-modal-content {
        position: relative;
        border-radius: 20px;

        .ant-modal-header {
            border-radius: 20px 20px 0 0;

            .ant-modal-title {
                font-weight: normal;
            }
        }

        .ant-modal-body {
            padding: 0 40px 20px 40px;

            .hoverShow {
                display: none;
                position: relative;

                p {
                    line-height: 16px;
                    height: 16px;
                }

                span {
                    width: 0;
                    height: 0;
                    border-width: 0 10px 10px;
                    border-style: solid;
                    border-color: transparent transparent black;
                    position: absolute;
                    left: 46.5%;
                    top: -10px;
                }
            }

            .iconTip {
                img {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: absolute;
                    left: 63%;
                    top: -39px;
                    cursor: pointer;
                }
            }

            .iconTip:hover .hoverShow {
                display: block;
                width: 350px;
                height: 180px;
                color: #fff;
                font-size: 12px;
                background-color: black;
                position: absolute;
                left: 38%;
                top: -5px;
                z-index: 100;
                padding: 10px 8px;
                border-radius: 10px;
            }

            .ant-row {
                margin-left: 0px !important;
                margin-right: 0px !important;
                margin-bottom: 5px !important;

                .ant-col-12 {
                    margin-bottom: 35px;
                    font-size: 12px;
                    height: 120px;

                    &:nth-child(odd) {
                        padding-left: 0px !important;
                        padding-right: 20px !important;
                    }

                    &:nth-child(even) {
                        padding-left: 20px !important;
                        padding-right: 0px !important;
                    }

                    &:last-child {
                        padding-top: 21px;
                    }

                    span {
                        color: #666666;
                    }

                    label {
                        font-size: 12px;
                        color: #222222;
                    }

                    .ant-form-item-control .ant-form-item-children {
                        position: relative;

                        .money-icon {
                            position: absolute;
                            left: 10px;
                        }

                        .ant-input {
                            padding-left: 20px;
                        }

                        .baifen-icon {
                            position: absolute;
                            right: 10px;
                        }
                    }
                }
            }

            .settingRequirement {
                padding: 12px 16px;
            }

            .btnRow-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;

                .ant-col-6 {
                    text-align: center;
                    border-radius: 6px;
                    height: 40px;
                    line-height: 40px;
                    cursor: pointer;

                    &:first-child {
                        color: #000;
                        border: 1px solid #cccccc;
                    }

                    &:last-child {
                    }
                }
            }

            .ant-btn {
                width: 320px;
            }

            .btnactive {
                color: #fff;
                background-color: #00a6ff;
            }

            .btnnoactive {
                color: #fff;
                background-color: #cccccc;
            }
        }
    }
}

.tip-modal .ant-modal-body {
    background-color: transparent !important;
    padding: 0 !important;
}

.ant-modal.ant-modal-confirm.right-angle .ant-modal-content {
    border-radius: 0;
}

//滑动验证
.fpcaptch-modal {
    z-index: 2002;
    .ant-modal-close-x {
        color: #222222;
    }
    .ant-modal-body {
        padding: 16px 16px 10px;
    }

    .fpcontent {
        .fpSection {
            width: 100%;
            height: 150px;
            position: relative;

            .moveElement {
                width: 60px;
                height: 60px;
                position: relative;
                cursor: pointer;
            }

            .info {
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: #000;
                opacity: 0.7;
                left: 0;
                top: 0;
                color: #fff;

                img {
                    margin: 10px 0 0 90%;
                    width: 18px;
                    height: auto;
                    cursor: pointer;
                }

                p {
                    padding: 15px;
                }
            }

            .verifySuccess {
                width: 100%;
                height: 40px;
                line-height: 40px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #10a0004a;
                color: #42d200;

                img {
                    width: 18px;
                    height: auto;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            .verifyFail {
                width: 100%;
                height: 40px;
                line-height: 40px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #f92d2d61;
                color: #f00;

                img {
                    width: 18px;
                    height: auto;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }

        .fpFooter {
            margin: 10px 0 0;

            img {
                cursor: pointer;

                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }
}

.uploadImages-modal {
    .ant-modal-title {
        font-size: 16px;
    }

    .tipTextp {
        font-size: 14px;
        color: #222222;
    }

    ul li {
        margin-bottom: 10px;

        & > span {
            font-size: 12px;
            color: #666;
        }

        .ant-btn {
            height: 40px;
            background-color: #f3f3f3;
            margin-top: 8px;

            > span {
                color: #1c8eff;
            }

            &:hover,
            &:focus {
                border-color: 1px solid #ccc;
            }
        }

        .conbtn {
            width: 100%;
            height: 43px;
            border-radius: 8px;
            padding: 0 10px;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f3f3f3;

            .ant-btn:hover,
            .ant-btn:focus {
                border: 1px solid #e0e0e0;
                color: #999999;
            }

            span {
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            div {
                width: 20px;
                height: 20px;
                background-color: #00000066;
                border-radius: 13px;
                line-height: 18px;
                text-align: center;

                img {
                    width: 12px;
                    height: 12px;
                    vertical-align: middle;
                }
            }
        }

        &:last-child {
            .ant-btn {
                width: 46%;
                font-size: 14px;
                border-radius: 19px;

                &:first-child {
                    margin-right: 4%;

                    > span {
                        color: #666666;
                    }
                }

                &:last-child {
                    background-color: #cccccc;
                    margin-left: 4%;

                    > span {
                        color: #fff;
                    }
                }

                &.active {
                    background-color: #00a6ff;
                    color: #ffffff;
                }
            }
        }
    }
}

// 紧急公告
.ant-modal.announcementModal {
    padding: 0;
    background: url(/cn/img/home/base.png) 0 0 no-repeat;
    background-size: cover;
    z-index: 1700;
    .ant-modal-content {
        width: 100%;
        height: 100%;
        background-color: transparent;
        .ant-modal-header {
            background: transparent;
            .ant-modal-title {
                padding-left: 40%;
                color: #00a6ff;
                font-size: 28px;
            }
        }
        .ant-modal-body {
            width: 100%;
            padding: 0;
            .celine {
                position: absolute;
                left: 0;
                top: -15px;
                width: 356px;
                height: 315px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .ant-row {
                width: 100%;
                height: 246px;
                .ant-col-14 {
                    height: 100%;
                }
                .title {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    color: #222222;
                }
                .content {
                    width: 100%;
                    height: 130px;
                    font-size: 14px;
                    color: #222222;
                    font-weight: bold;
                    margin-bottom: 20px;
                    padding-right: 20px;
                    overflow: auto;
                }
                .footer {
                    width: 100%;
                    line-height: 34px;
                    .clearfix;
                    .left {
                        float: left;
                        width: 40%;
                        text-align: center;
                        color: #222222;
                        margin-left: 5%;
                        .ant-radio-wrapper .ant-radio-inner {
                            border-color: #00a6ff;
                            background-color: transparent;
                        }
                    }

                    .right {
                        float: right;
                        text-align: center;
                        width: 30%;
                        margin-right: 5%;
                        padding: 0 16px 0 6px;
                        line-height: 34px;
                        height: 34px;
                        background-color: #fff;
                        border-radius: 10px;
                        color: #00a6ff;
                        font-weight: 700;
                        border: 1px solid #00a6ff;
                        cursor: pointer;

                        .live-service {
                            width: 30px;
                            height: 30px;
                            display: inline-block;
                            box-shadow: none;
                            margin: 0 4px 0 0;
                            background-position: 0 -81px;
                            vertical-align: -0.7em;
                        }

                        .tlc-sprite {
                            background-image: url(/cn/img/home/sprite20220315.png);
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
    }
}

// USDT提款 存款
.UsdtTeachModalStyle .ant-modal-body {
    padding: 0;

    .ant-tabs-bar {
        border-bottom: none;
        margin: 0;
        padding-top: 15px;
    }
    .ant-tabs-tab {
        font-family: Microsoft YaHei, Bold;
        font-weight: bold;
    }
}

.UsdtTeachModalStyle .ant-modal-close-x {
    font-size: 20px;
    color: white;
    height: 51px;
    line-height: 51px;
}

.Otptxt {
    color: #222222;
    font-weight: bold;
}
.modal-close-hide .ant-modal-close {
    display: none;
}

.hide-ant-modal-close-x-modal .ant-modal-close-x{
    visibility: hidden;
}
.modal-show-blue-cancel-button .ant-modal-confirm-btns .ant-btn:not(.ant-btn-primary) {
    color: #00a6ff;
}

// 忘記密碼/ 忘記用戶名
.confirm-modal-of-public,
.confirm-modal-of-forgetuser {
    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-confirm-body {
            .ant-modal-confirm-title {
                color: #fff;
                background-color: #00a6ff;
                padding: 19px 100px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            .ant-modal-confirm-content {
                padding-bottom: 0;
                margin-bottom: 39px;
                color: #222222;
                padding-left: 30px;
                padding-right: 30px;
                &:has(.ODPmodals) {
                    margin-bottom:20px
                }
                .suggestion-list-when-no-ODP-banks-wrap {
                    background:#EFEFF4;
                    border-radius:8px;
                    padding:12px;
                    margin:12px 0 0;
                    >div{
                        cursor:pointer;
                        background:url(/cn/img/icon/right.svg) no-repeat #fff 100% 50%;
                        border-radius:8px;
                        text-indent: 3px;
                        width:100%;
                        margin-bottom:8px;
                        height:40px;
                        line-height:40px;
                        .deposit-sprite {
                            display:inline-block;
                            vertical-align:middle;
                        }
                    }
                    >div:last-child{
                        margin-bottom:0
                    }

                    .isFast::after,
                    .isNew::after {
                        content: "新";
                        background-color: #f92d2d;
                        margin-left:8px;
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        font-size: 10px;
                        transform: scale(1);
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                    }
                    .isFast::after {
                        content: "极速";
                        background-color: #2157eb;
                        width: 28px;
                    }
                }
            }
        }

        .ant-modal-confirm-btns {
            margin: 0 30px 30px 30px;
        }
    }
}

.suggestion-list-when-no-ODP-banks-modal {
    &.confirm-modal-of-public {
        .ant-modal-content {
            .ant-modal-confirm-body {
                .ant-modal-confirm-content {
                    margin-top: 16px;
                    padding: 0 20px;
                }
            }
        }
        .ant-modal-content .ant-modal-confirm-btns {
            margin: 0 20px 30px 20px
        }
    }
}

.confirm-modal-of-public-tight {
    .ant-modal-content {
        .ant-modal-confirm-body {
            .ant-modal-confirm-content {
                margin: 16px auto;
            }
        }
    }
}
.confirm-modal-of-public-v202409 { // see cxpc699 mockup
    width: 400px!important;
    .ant-modal-content {
        .ant-modal-confirm-body {
            font-size: 14px;
            text-align: center;
            .topicon {
                margin:0 auto 16px;
                width:56px;
                display:block;
            }
            .ant-modal-confirm-title {
                font-size: 18px;
                padding: 12px 0;
            }
            .ant-modal-confirm-content {
                color: #666;
                margin: 16px auto;
                padding: 0 16px;
            }
        }
        .ant-modal-confirm-btns {
            margin: 0;
            padding: 16px;
            width: 100%;
            display: flex;
            button {
                width: 100%;
                height: 40px;
                font-size: 16px;
            }
            button[class="ant-btn"] {
                color: #00A6FF;
            }
        }
    }
}

.process-vline {
    margin:0;
    padding:0;
    li::after {
        position:absolute;
        content:'';
        display:block;
        width:2px;
        height:40px;
        background:#BCBEC3;
        border-radius:2px;
        margin-top:5px;
        left:7px;
    }
    li:last-child::after {
        display:none
    }
    li {
        text-align:left;
        padding-left:20px;
        margin:0 0 50px;
        background: url(/cn/img/icons/bullet-empty.svg) no-repeat 0 3px;
        background-size: 16px 16px;
        color:#999;
        position:relative;
    }
    li:last-child {
        margin:0;
    }
    &.step1 {
        li:nth-child(1) {
            color: #00A6FF;
            background-image: url(/cn/img/icons/bullet-blue.svg);
        }
        li:nth-child(2),
        li:nth-child(3) {
            background-image: url(/cn/img/icons/bullet-empty.svg);
        }
    }
    &.step2 {
        li:nth-child(1) {
            background-image: url(/cn/img/icons/bullet-checked.svg);
        }
        li:nth-child(2) {
            color: #FABE47;
            background-image: url(/cn/img/icons/bullet-yellow.svg); 
        }
        li:nth-child(3) {
            background-image: url(/cn/img/icons/bullet-empty.svg);
        }
    }
    &.step3 {
        li:nth-child(1),
        li:nth-child(2) {
            background-image: url(/cn/img/icons/bullet-checked.svg);
        }
        li:nth-child(3) {
            color: #EB2121;
            background-image: url(/cn/img/icons/bullet-red.svg); 
        }
    }
        
    li>div,
    li>div img{
        color: #00A6FF;
        float:right
    }
    li>div img{
        margin-top:3px
    }
}

.wallet-deposit-warning {
    &.confirm-modal-of-public {
        .ant-modal-content {
            .ant-modal-confirm-body {
                .ant-modal-confirm-content {
                    margin-bottom: 0;
                    padding: 0 25px;
                }
            }
        }
    }
}

.ReferModal {
    .RecommendedGames {
        text-align: left;
        padding-top: 20px;
        h4 {
            text-align: left;
            padding-top: 20px;
        }
    }
}

// 驗證超過次數Modal
.exceed-modal {
    .ant-modal-content {
        .ant-modal-body {
            .otp-modal-title {
                font-size: 18px;
                margin: 13px auto;
            }
        }
    }
}

.ant-modal-confirm.ant-modal-confirm-confirm.up-yes-bottom-no-block-btns-modal {
    .ant-modal-confirm-content {
        margin: 16px 0;
    }
    .ant-modal-confirm-btns {
        display: flex;
        flex-direction: column-reverse;
        .ant-btn { /* cancel */
            display:block;
            margin:10px 0 0;
            width:100%;
        }
        .ant-btn+.ant-btn { /* ok */
            margin:0
        }
    }
}

// general large feedback modal
.large-feedback-status-modal {
    .anticon {
        display: none;
    }

    .ant-modal-confirm-content {
        img {
            width: 60px;
            height: 60px;
        }
    }
    .ant-modal-confirm-btns {
        display: flex;
    }
}

.ant-modal-content {
    box-shadow: unset;
}

.SecurityAnnouncement {
    .ant-modal-content {
        .ant-modal-body {
            padding: 30px;
            background-color: white !important;

            .otp-modal-banner {
                margin-left: -30px;
            }

            .otp-modal-description {
                margin: 30px 0 20px 0;
                padding: 0;
            }
        }
    }
}
.StepsBox {
    .ant-steps-item-wait .ant-steps-item-icon {
        border-color: #ccc !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: #ccc !important;
    }
}

.loginOTPModal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 22px 30px 30px 30px;
            background-color: #efeff4;
            border-radius: 0 0 20px 20px;

            .otp-modal-description {
                padding: 20px;
                margin: 0 0 20px 0;

                .ant-btn {
                    &.otpBtn {
                        height: 40px;
                        margin: 16px 0 0 0;
                    }
                }
            }

            .OtpList {
                justify-content: center;
                gap: 16px;

                .otpBox {
                    width: 100%;
                    padding: 20px 16px;

                    .ant-btn-primary {
                        width: 130px;
                        height: 44px;
                        font-weight: normal;
                    }
                }
            }

            .ant-btn-background-ghost {
                height: 40px;
            }
        }
    }
}

.StepsBox {
    width: 200px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.forgetPwdModal {
    .ant-modal-content {
        .ant-modal-body {
            .ant-modal-confirm-content {
                padding: 0 29px;
            }
        }
    }
}

.superdoor-modal{
    .ant-modal-title{
        text-align: center;
    }
    .ant-modal-body {
        padding: 0 !important;
        border-radius: 10px;
        overflow: hidden;
        width: 600px;
    }
    .superdoor-modal-section{
        width: 600px;
        padding: 20px 40px;
        .checkbox-button-container{
            width: 300px;
            margin:  auto;
            .ant-checkbox-input{
                width: 16px;
                height: 16px;
            }
            .ant-checkbox + span{
                padding-left: 0;
            }
            .ant-checkbox-inner::after{
                left: 40%;
            }
        }
        h3{
            font-size: 16px;
            color: #000;
            font-weight: bold;
        }
        .know{
            width: 300px;
            height: 40px;
            margin: 20px auto;
        }
        .domain-wrap{
            width: 300px;
            border: 1px solid #E0E0E0;
            border-radius: 8px;
            margin: 20px auto;
            .item-domain{
                width: 100%;
                height: 44px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #E0E0E0;
                padding: 0 10px;
                .left{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                .copy-icon{
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                    margin-left: 10px;
                }
                .goTo{
                    width: 72px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 4px;
                    border: 1px solid #00A6FF;
                    color: #00A6FF;
                    cursor: pointer;
                }
            }
        }
    }
}

.modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgb(34 34 34 / 0%);
    filter: alpha(opacity=50);
}

.email-suggestions {
	position: absolute;
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	border-radius: 6px;
	z-index: 3;
	overflow: hidden;
    text-align: left;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
	li + li{
		border-top: 1px solid #e0e0e0;
		width: 100%;
	}

	.email-suggestion {
		padding:8px 10px;
        width: 100%;
        cursor: pointer;
        &:hover{
            background-color: #00a6ff;
            color: white;
            width: 100%;
        }
        z-index: 3;
	}
}

.from2024design-modal {
    .ant-btn:not(.ant-btn-primary) {
        color: #00A6FF;
    }
}

