@use autoprefixer;

.wallet-modal.ant-modal {
    min-width: 750px;
}
.wallet-modal .ant-modal-body {
    min-height: 588px;
    margin-top: 18px;
}
.wallet-modal .ant-modal-header {
    padding-top: 20px;
    text-align: center;
    background: white;
    .ant-modal-title {
        color: #222222 !important;
        font-size: 17px;
        font-weight: bold;
    }
}
.wallet-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
    color: #000;
}

.allOption {
    display: flex;
    justify-content: space-between;
    padding-right: 5%;
}

.drop-area {
    position: relative;
    margin-bottom: 6px;
}

.transferWellet .ant-select-dropdown-menu-item-active {
    background-color: #f0f0f2 !important;
}
.transferWellet .ant-select-dropdown-menu-item-selected {
    background-color: #f0f0f2 !important;
}

.drop-area .ant-select-dropdown-menu-item-selected {
    .allOption .optionItem2::after {
        content: url("/cn/img/wallet/icon-add.svg");
        margin-left: 5px;
        position: absolute;
        right: 10px;
    }
}

.drop-area-promot {
    display: flex;
    // flex-wrap: nowrap;
    justify-content: space-around;
    margin-bottom: 25px;
    .ant-select-dropdown {
        top: 49px !important;
    }
    .ant-select-dropdown-menu {
        max-height: 160px;
    }
    .ant-select-dropdown-menu-item .option-item {
        width: 280px;
        text-align: left;
        white-space: normal;
    }
    .ant-select-dropdown-menu-item-selected {
        .option-item::after {
            content: url("/cn/img/wallet/icon-add.svg");
            // width: 100%;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

// .drop-area-promot .ant-select-dropdown-menu-item-selected::after {
// 	content: url("/cn/img/wallet/icon-add.svg");
// 	// width: 100%;
// 	position: absolute;
// 	left: 370px;
// }

// 钱包左侧导航
.deposit-list-wrap {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
    border-bottom: 1px solid #e0e0e0;
}
.wallet-wrap {
    position: relative;
    display: flex;
}
.wallet-nav-wrap {
    //position: absolute;
    flex: 0 0 30%;
    left: 0;
    top: 0;
    width: 180px;
    z-index: 2;
}
.wallet-nav-item {
    padding: 12px 0;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    cursor: pointer;
}
.wallet-nav-item.active,
.wallet-nav-item.open {
    color: @theme;
    font-weight: bold;
}
.wallet-nav-item .anticon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #999;
    line-height: 48px;
    transform: rotate(270deg);
    transition: transform 0.3s;
    // background-image: url(/cn/img/wallet/icon-arrow-up.svg);
    // background-repeat: no-repeat;
}
.wallet-nav-item.open .anticon {
    transform: rotate(360deg);
}
.wallet-nav-item:last-child,
.wallet-nav-item.border-clear {
    border: 0;
}
.deposit-list {
    margin: 0 8px;
    overflow: hidden;
}
.deposit-list .deposit-item {
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    line-height: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    &.isFast::after {
        content: "极速";
        width: 48px;
        height: 29px;
        line-height: 29px;
        font-size: 10px;
        background-color: #2157eb;
        transform: scale(0.8);
        border-radius: 6px;
        position: absolute;
        top: 6px;
        text-align: center;
        right: 4px;
        color: #fff;
    }
    &.new::after,
    &.isNew::after {
        content: "新";
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 10px;
        background-color: #f92d2d;
        transform: scale(0.8);
        border-radius: 6px;
        position: absolute;
        top: 6px;
        text-align: center;
        right: 4px;
        color: #fff;
    }
}
.deposit-list .deposit-item:last-child {
    margin-bottom: 16px;
}
.deposit-list .deposit-item .deposit-sprite {
    vertical-align: middle;
    display: inline-block;
    // width: 28px;
    // height: 24px;
    margin-right: 6px;
    margin-left: 10px;
}
// .deposit-list .deposit-item .deposit-sprite {
//     background-position: -250px -228px;
// }
// .deposit-list .deposit-item .deposit-sprite.BCM {
// 	background-image: url(/cn/img/home/kuaijie.png);
// 	background-position: center !important;
// }
.deposit-list .deposit-item > span {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 96px;
    display: inline-block;
}
.deposit-list .deposit-item:hover,
.deposit-list .deposit-item.active {
    background-color: #f0f0f2;
    font-weight: bold;
}

// 右侧内容公用
.wallet-content-wrap {
    //margin-left: 228px;
    flex: 0 0 70%;
    padding: 30px;
    min-height: auto;
    background-color: #f0f0f2;
    border-radius: 10px;

    &:has(.ODP-2nd-form-wrap) {
        min-height: auto; /* hotfix for ODP */
    }

    .ant-calendar-picker {
        display: block;
    }

    .PromoContent {
        text-align: center;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #bfbfbf;
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: -25px;
        .list {
            display: flex;
            justify-content: space-between;
        }
    }
}
.ant-form-item-with-help.deposit-moneyinput {
    .has-error {
        .ant-form-explain:empty {
            display: none;
        }
    }
    .has-error:has(.ant-form-explain:empty) {
        input {
            border: 1px solid #bfbfbf;
        }
        input:hover {
            border-color: #333;
        }
        input:focus {
            border-color: #00a5fd;
        }
    }
    margin-bottom: 10px;
}
.deposit-help-link {
    text-align: right;
    margin-bottom: 16px;
    display: block;
}
.coin-rate-hint {
    margin-top:-10px;
    background:rgb(231,231,231);
    color:rgb(144,144,144);
    border-radius:10px;
    padding:12px;
}
.ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
}

.ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-active {
    color: #00a5fd;
}
.ant-select-dropdown-menu-item-disabled:hover {
    opacity: 0.5; /* 修改禁用选项的透明度 */
    color: #bcbec3 !important;
}
.ant-select-dropdown-menu-item-disabled {
    opacity: 0.5; /* 修改禁用选项的透明度 */
    cursor: not-allowed; /* 修改鼠标样式为不允许（禁用） */
    /* 其他样式调整 */

    color: #bcbec3 !important; /* 修改文本颜色 */
}

.transferAllItem {
    border-radius: 10px;
    .ant-select-dropdown-menu-item {
        border-bottom: 1px solid #f2f2f2;
    }
    .transferItem.UnderMaintenance {
        i,
        .localizedName,
        .Maintenance {
            filter: grayscale(100%);
            opacity: 0.4;
        }
        .Maintenance {
            padding-right: 10px;
        }
    }
    .transferItem {
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 2% 2%;
        .transferDotName {
            i {
                padding-left: 10px;
            }
            span {
                padding-left: 10px;
            }
        }
        .transferMonImg {
            cursor: pointer;
            span {
                padding-right: 10px;
            }
            img {
                padding-right: 10px;
            }
        }
    }
    .totalBalItem {
        display: flex;
        justify-content: space-between;
        padding: 2% 2%;
        border-radius: 10px;
        background-color: white;
        margin-bottom: 15px;
        .totalBal {
            span {
                padding-left: 10px;
            }
        }
    }
    .totalBalMonImg {
        padding-right: 10px;
        span {
            padding-right: 10px;
        }
    }
}
.infoError{
    background-color: #E0E0E0 !important;
    color: #666666 !important;
}
.modal-prompt-info {
    padding: 12px;
    background-color: #fff5bf;
    // margin-bottom: 20px;
    border-radius: 8px;
    color: #83630b;
    // margin-top: 5px;
    margin: 10px 0;
    font-size: 12px;
    .spanText {
        border-bottom: 1px solid #2962ff;
    }
    .pText {
        margin: 0.2rem 0 0;
        color: #222;
        font-size: 12px;
    }
    .DONENOTE {
        font-size: 14px;
        background-color: #fff5bf;
        padding: 16px;
        line-height: 20px;
        color: #83630b;
        border-radius: 8px;
        h3 {
            font-weight: bold;
            color: #83630b;
            margin: 0;
            font-size:14px;
        }
        ul {
            margin: 0 0 0 13px;
            li {
                list-style: decimal outside
            }
        }
        .red {
            color: red;
        }
    }
}

.moneyError {
    .modal-prompt-info {
        padding: 0px 12px;
        background-color: #fee0e0;
        margin-bottom: 5px;
        border-radius: 8px;
        color: #eb2121;
        margin-top: 20px;
    }
}

.ant-steps-vertical.ant-steps-small
    .ant-steps-item-container
    .ant-steps-item-title {
    color: #00a5fd !important;
    font-weight: bold;
}

.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-size: 12px;
}

.modal-waining-info {
    padding-right: 6px;
    padding-left: 6px;
    margin-top: -6px;
    margin-bottom: 10px;
}
.deposit-help-wrap {
    margin-top: 20px;
    &.smallRiver-help-wrap {
        .smallRiver-warnstop {
            width: 100%;
            text-align: center;
            img {
                width: 64px;
                height: 60px;
                margin-bottom: 20px;
                margin-top: 30px;
            }
            p {
                text-align: center;
                color: #f53d3d;
                font-weight: bold;
                font-size: 17px;
                margin-bottom: 0.5rem !important;
            }
        }
        ul {
            background: #fff5bf;
            border-radius: 8px;
            font-size: 12px;
            padding: 10px 16px 10px 30px !important;
            li {
                color: #83630b !important;
                padding: 0px !important;
                line-height: 2;
            }
        }
        .footerbtn {
            width: 100%;
            line-height: 40px;
            margin-top: 20px;
            cursor: pointer;
            p {
                float: left;
                width: 45%;
                height: 38px;
                line-height: 38px;
                text-align: center;
                border-radius: 8px;
                color: #00a6ff;
                border: 1px solid #00a6ff;
                margin: 0 2.5%;
            }
            div {
                float: left;
                width: 45%;
                text-align: center;
                border-radius: 8px;
                margin: 0 2.5%;
                color: #ffffff;
            }
            .inactiv {
                background-color: #cccccc;
                pointer-events: none;
                position: relative;
                img {
                    width: 22px;
                    height: 22px;
                    margin-right: 5px;
                }
                span {
                    position: absolute;
                    left: 26%;
                    top: 2px;
                    font-size: 12px;
                }
            }
            .active {
                background-color: #00a6ff;
            }
        }
    }
}
.deposit-help-wrap > h4 {
    color: #222;
}
.deposit-help-wrap > ul {
    padding-left: 20px;
    margin-bottom: 10px;
    width: 99%;
    text-align: justify;
}
.deposit-help-wrap > ul > li {
    color: #666;
    padding: 4px 0;
    line-height: 1.4;
    list-style: decimal;
}
.deposit-help-wrap .ant-btn.ant-btn-link,
.inline.ant-btn.ant-btn-link {
    padding: 0;
    height: auto;
}

.deposit-help-wrap .displayTip {
    margin-top: 15px;
}
.deposit-help-wrap .displayTip li {
    margin: 10px 0;
}
.deposit-help-wrap .displayTip li .tipSurContent {
    margin: 10px 0;
}
// .other-account-wrap {
// 	padding: 3px 0;
// 	.ant-col.ant-form-item-label {
// 		padding: 0;
// 		& > label {
// 			color: #666;
// 		}
// 	}
// 	.ant-form-item-control {
// 		line-height: 35px;
// 	}
// }
// 右侧单据
.tlc-deposit-receipt {
    color: #222;
    box-shadow: 0px 2px 4px #ddd;
    background-color: #fff;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 13px;
    position: relative;
    &.radius {
        border-radius: 8px;
    }
    h2 {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
    }
    .item-label {
        color: #222222;
    }
    span:not(.ant-form-item-children) {
        display: inline-block;
        padding: 3px 0;
    }
    span:not(.item-label):not(.ant-form-item-children) {
        max-width: 80%;
        text-align: right;
    }
    ul > li {
        padding: 10px 15px;
        border-bottom: 1px solid #f3f3f3;
    }
    .ant-form-item-control {
        line-height: 32px;
    }
    .other-account-wrap {
        padding: 10px 15px;
    }
    .ant-btn.ant-btn-link {
        height: auto;
        text-align: left;
        padding: 0;
        &.tlc-inline-btn {
            padding: 0;
            position: absolute;
            top: -1px;
            right: 6px;
            line-height: 28px;
            & > span {
                max-width: initial;
            }
        }
    }
    .tipBox {
        padding: 10px;
        position: absolute;
        top: 120px;
        left: 0;
        z-index: 1;
        .tipContent {
            padding: 10px;
            background: rgba(0, 0, 0, 0.8);
            color: rgb(255, 255, 255);
            padding: 10px;
            border-radius: 10px;
            text-align: justify;
            .tipArrow {
                width: 0px;
                height: 0px;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 10px solid #000000cc;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 7%;
            }
            .closeTip {
                color: #ffee00;
                text-align: right;
                cursor: pointer;
            }
        }
    }
    .plus-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        margin-top: 10px;
        img {
            margin-right: 5px;
        }
    }

    // 上传收据盒子
    .upload-wrapper {
        .BtnBg {
            background: #ffe273;
            font: normal normal 600 12px/17px PingFang SC;
            padding: 3px 10px;
            margin-left: 5px;
            border-radius: 4px;
        }
        & > h2 {
            font-weight: 400;
            color: #000;
        }
        .item-label {
            padding-bottom: 10px;
        }
        .ant-btn.ant-btn-block {
            background-color: #f0f0f2;
            border-color: #f0f0f2;
            color: #000;
            text-align: left;
            cursor: default;
            padding: 0 15px;
            margin: 10px 0;
            & > span {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.link {
                cursor: pointer;
                color: #999999;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .smdeposit {
        padding: 10px 15px;
        .item-label {
            width: 100%;
            text-align: left;
        }
    }
}
//INVOICE_AUT充值
.INVOICE-AUT-form {
    .INVOICE_AUT_Row {
        .ant-form-item {
            // margin: 0;
            .ant-form-item-label > label::after {
                content: " *";
                color: @theme;
                display: inherit;
            }
        }
        .errorTip {
            background: #fee5e5 0% 0% no-repeat padding-box;
            border-radius: 8px;
            padding: 10px;
            color: #eb2121;
            margin-top: 10px;
            height: auto !important;
            width: 100%;
            display: block;
        }
    }
    .btnActive {
        background-color: #00a6ff;
        color: #fff;
        &:hover {
            background-color: #00a6ff;
            color: #fff;
        }
    }
    .btnNoActive {
        background-color: #cccccc;
        color: #fff;
        &:hover {
            background-color: #cccccc;
            color: #fff;
        }
    }
}
// 右侧充值结果
.lb-third-step-wrap {
    text-align: center;
    .bold {
        font: normal normal bold 14px/17px Microsoft YaHei;
    }
    .ant-steps-vertical .ant-steps-item-description {
        color: #999999 !important;
        font-size: 12px;
    }
    .note {
        padding: 10px 0;
        color: #999999 !important;
    }
    .StepsBox {
        width: 100%;
        text-align: left;
        font-size: 12px;
        padding: 15px;
        .firstStep {
            .ant-steps-item-tail {
                position: absolute !important;
                top: 7px !important;
                left: 8px !important;
                padding: 10px 0 0px !important;
            }
            .ant-steps-item-tail::after {
                background-color: #cccccc !important;
                width: 2px !important;
            }
        }

        .lastStep {
            left: 2px !important;
            .ant-steps-item-description {
                position: relative !important;
                top: 3.5px !important;
            }
        }
    }
    .stepDone {
        background-color: white;
        padding-bottom: 1rem;
        .successtext {
            padding: 10px;
            font: normal normal 600 18px/24px PingFang SC;
        }

        .iconstatus {
            padding-top: 30px;
        }
    }
    .cuccess {
        color: @success-color;
    }
    .check-success > div:not(.success) {
        font-weight: bold;
    }
    .anticon {
        font-size: 64px;
        margin: 15px 0 20px 0;
        color: #42d200;
    }
    & > p {
        margin: 20px 0 40px;
    }
    .smallRiver-p {
        font-size: 13px;
        padding: 0 20px 0px 20px;
        text-align: left;
        color: #666666;
        margin-top: -20px;
        span {
            color: #f92d2d;
            padding: 0 0 0 5px;
        }
    }
    .smallRiver-border {
        padding: 8px 20px;
        border: 1px solid #f0f0f2;
        border-right: none;
        border-left: none;
        font-size: 13px;
    }
}

.showList {
    display: flex;
    justify-content: space-between;
}

// 单选框
.wallet-radio-wrap {
    display: block;
    .wallet-radio {
        box-sizing: border-box;
        padding: 0 11px;
        line-height: 45px;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

// 多选框（提款新增银行卡以及默认银行卡样式）
.ant-select-dropdown-menu-item.withdrawal-new-bank {
    color: #52a9ff !important;
    &::after {
        content: "+";
        font-size: 20px;
        text-indent: -13px;
        float: right;
    }
}
.ant-select-dropdown.widthdraw-bank-card
    .ant-select-dropdown-menu-item
    .anticon.anticon-check {
    float: right;
    color: #fff;
    line-height: 32px;
}
.ant-select-dropdown.widthdraw-bank-card
    .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected
    .anticon.anticon-check {
    color: #52a9ff;
}
.ant-select-selection-selected-value .anticon.anticon-check {
    display: none;
}

.ant-select-lg.placeholder-block .ant-select-selection__placeholder {
    display: block !important;
    color: rgba(34, 34, 34, 0.8);
}

// 转账
.split-line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin-bottom: 20px;
}
.card-balance-wrap {
    color: #222;
    min-height: 72px;
    box-shadow: 0px 2px 4px #ddd;
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 6px;
    padding-top: 14px;
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    &:nth-child(2) {
        padding-bottom: 14px;
        line-height: 44px;
        .ant-col:first-child {
            line-height: 1.6;
        }
        .anticon {
            margin-left: 6px;
            columns: #111;
            cursor: pointer;
        }
    }
    .balance-box {
        width: 126px;
        float: left;
        margin: 0 6px;
        padding-top: 4px;
        border-radius: 5px;
        border: 2px solid transparent;
        overflow: hidden;
        transition: all 0.2s;
        cursor: pointer;
        div:not(.btn) {
            width: 86%;
            margin: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .btn {
            color: #fff;
        }
        &:hover {
            border-color: @theme;
            .btn {
                background-color: @theme;
            }
        }
    }
}

// 钱包教程遮罩
.wallet-learn-wrap {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #666;
    background-repeat: no-repeat;
    border-radius: 6px;
    &.withdraw {
        background-image: url("/cn/img/learn/withdraw_learn.png");
        .learn-knew {
            margin-top: 462px;
            margin-left: 400px;
        }
    }
    &.transfer {
        height: 1034px;
        background-image: url("/cn/img/learn/transfer_learn.png");
        .learn-knew {
            margin-top: 810px;
            margin-left: 400px;
        }
    }
}

/* 充值渠道 */
.Pay_Radio {
    .ant-radio-wrapper {
        width: 148px;
        height: 46px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        line-height: 46px;
        padding-left: 10px;
        margin-bottom: 10px;
        padding: 0 5px;
        &.AliPOB::after {
            content: "新";
            width: 48px;
            height: 29px;
            line-height: 29px;
            font-size: 14px;
            border-radius: 0px 15px 0px;
            background-color: #eb2121;
            transform: scale(0.7);
            position: absolute;
            top: -4px;
            text-align: center;
            right: -7px;
            color: #fff;
        }
    }
    &.OA,
    &.WC {
        .ant-radio-wrapper {
            width: 202px;
            padding-left: 12px;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
    &.PPB {
        .ant-radio-group {
            display: flex;
            max-width: 440px;
            flex-wrap: wrap;
        }
        .ant-radio-wrapper {
            display: flex;
            align-items: center;
            line-height: unset;
            white-space: normal;
            width: 202px;
            padding-left: 12px;
            margin-right: 16px;
            &:nth-child(2n) {
                margin-right: 0;
            }
            >:last-child {
                max-width: 160px;
                word-break: break-all;
            }
        }
    }
}
// 小额充值 Strat
.suggested-amount-wrap {
    overflow: hidden;
    ul {
        margin-top: -8px;
    }
    li {
        float: left;
        text-align: center;
        cursor: pointer;
        border: 1px solid #e0e0e0;
        background-color: #fff;
        width: 96px;
        height: 45px;
        line-height: 45px;
        border-radius: 10px;
        margin-top: 8px;
        margin-right: 10px;
        &.true {
            line-height: 41px;
            color: #00a6ff;
            border-color: #00a6ff;
        }
        &.disabled {
            cursor: default;
            color: #cccccc;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
// 小额充值 End
.SuggestedAmountDiv {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #e0e0e0;
    border-radius: 4px;
    padding: 15px 0 15px 15px;
    color: #000;
    font-size: 13px;
    border: 0;
    margin-top: 10px;
    &:after {
        display: table;
        content: "";
        clear: both;
    }
    .arrow {
        position: absolute;
        top: -10px;
        left: 10px;
        border-bottom: 12px solid #e0e0e0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    p {
        text-align: left;
        height: 10px;
        line-height: 10px;
    }
    ul {
        li {
            float: left;
            width: 90px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 8px;
            margin-top: 8px;
            border: 0;
            border-radius: 8px;
            background: white;
            cursor: pointer;
            &:hover {
                background-color: #00a6ff;
                color: white;
            }
            &.true {
                background-color: #00a6ff;
                color: white;
            }
        }
    }
}

// 加密支付
.ctc-wrapper {
    .ant-radio-wrapper {
        margin: 0;
    }
    .ant-radio {
        vertical-align: text-bottom;
    }
    // .ant-form-item-label > label::after {
    //     content: " *";
    //     color: @theme;
    // }
    .ant-form-item-label label[for="CTC_money"]::after {
        content: " ";
        color: @theme;
        display: inline-block;
    }
    .wallet-radio-wrap {
        width: 100%;
    }
    .wallet-radio-wrap .wallet-radio {
        width: 49%;
        &:nth-child(odd) {
            margin-right: 2%;
        }
    }
    .ctc-pay-method-item {
        width: 49%;
        float: left;
        height: 122px;
        text-align: center;
        background-color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-right: 2%;
        box-shadow: 0px 2px 4px #ebebeb;
        cursor: pointer;
        position: relative;
        &:nth-child(2n) {
            margin-right: 0;
        }
        &.curr {
            color: #fff;
            border-color: transparent;
            background-color: @theme;
        }
        &.USDT-ERC20,
        &.USDT-TRC20 {
            height: 122px;
            & > p {
                width: 70%;
                margin: auto;
            }
        }
        &.USDT-TRC20 .ctc-currency-tip {
            display: block;
        }
        i {
            width: 60px;
            height: 70px;
            display: block;
            margin: auto;
            &.BTC {
                margin-top:16px;
                width: 121px;
                height: 46px;
                background-position: -68px -361px;
            }
            // &.USDT-ERC20 {
            //   background-position: -245px -149px !important;
            // }
            &.USDT-ERC20 {
                width: 122px !important;
                height: 62px !important;
                background-position: -64px -300px !important;
            }
            &.USDT-TRC20 {
                width: 122px !important;
                height: 62px !important;
                background-position: -185px -306px !important;
            }
            &.ETH {
                background-position: -183px -149px !important;
            }
        }
    }
    .ctc-currency-tip {
        display: none;
        background-color: #2962ff;
        color: #fff;
        font-size: 12px;
        position: absolute;
        padding: 0 10px;
        right: 4px;
        top: 6px;
        border-radius: 5px;
        transform: scale(0.9);
    }
}
.wallet-prompt-ctc .ant-modal-confirm-content {
    margin: 0;
}
.ctc-prompt .ctc-pay-method-item {
    display: block;
    box-shadow: none;
    float: none;
    cursor: default;
    margin: auto;
}
.prompt-center-box {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    width: 116px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    overflow: hidden;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    color: #fff;
    z-index: 1;
}
//提现 本银
.currentWithdraw-lb {
    .wallet-radio-wrap {
        width: 100%;
        .ant-radio-wrapper {
            width: 49%;
            margin-right: 2%;
            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }
    .ant-form-item-label > label::after {
        content: "*";
        color: #f00;
        display: inherit !important;
    }
}
//提现 泰达币
.currentWithdraw-ccw {
    .add-ccw-withdrawMethod-btn {
        width: 100%;
        height: 43px;
        color: #00a6ff;
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid;
        border-radius: 8px;
        transition: all 0.3s;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-wrap {
        .active {
            background-color: #00a6ff;
            color: #fff;
        }
        .noactive {
            background-color: #cccccc;
            border: 1px solid #ccc;
            color: #fff;
        }
        .ant-btn[disabled],
        .ant-btn-disabled:hover,
        .ant-btn[disabled]:hover {
            background-color: #cccccc;
            color: #fff;
        }
    }

    .errorTip {
        width: 100%;
        line-height: 20px;
        color: #f92d2d;
        font-size: 12px;
    }
    .ant-select-lg .ant-select-selection__rendered {
        line-height: 22px;
    }
    .ant-select-selection-selected-value {
        line-height: 22px;
    }
    .modal-prompt-info {
        .goPage {
            color: #52a9ff;
            cursor: pointer;
        }
    }
    .ant-form-item-label > label::after {
        content: "*";
        color: #f00;
        display: inherit !important;
    }
    .ant-form-item-notrequired-label > label::after {
        display: none;
    }

    .modal-prompt-others {
        width: 100%;
        .modal-prompt-label-multiple,
        .modal-prompt-label {
            width: 100%;
            height: 43px;
            line-height: 43px;
            padding: 0 12px;
            background-color: #efeff4;
            color: #bcbec3;
            border:1px solid #bcbec3;
            margin-bottom: 20px;
            border-radius: 8px;
        }
        .modal-prompt-label-multiple {
            padding: 8px 12px;
            height: auto;
            line-height: 1.3;
        }
    }
    .ccw-withdrawal-amount-label {
        label {
            &::after {
                content: initial;
            }
            > div {
                > span:first-child::after {
                    content: "*";
                    color: #f00;
                    margin: 0 8px 0 2px;
                }
                display: flex;
                justify-content: space-between;
            }
        }
    }
    .ccw-withdrawal-hint {
        width: 100%;
        background-color: #e8e8eb;
        padding: 13px 15px;
        border: 1px solid #ccc;
        border-radius: 10px;
        color: #222;
        line-height: 20px;
    }
}
.ccw-locked-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 502px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    > p {
        color: #222;
        margin: 28px 0 0 0;
    }
}
// 提现泰达币添加钱包
.ant-select-dropdown-menu-item.selectTDBWallet-addBtn {
    color: #52a9ff !important;
    &::after {
        content: "+";
        font-size: 20px;
        text-indent: -13px;
        float: right;
    }
}
.ant-select-dropdown.selectTDBWallet .ant-select-dropdown-menu-item {
    text-align: left;
}
.ant-select-dropdown.selectTDBWallet
    .ant-select-dropdown-menu-item
    .anticon.anticon-check {
    float: right;
    line-height: 32px;
    color: #52a9ff;
}
.ant-select-dropdown.selectTDBWallet
    .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    background-color: #f0f0f2;
    .anticon.anticon-check {
        color: #52a9ff;
    }
}

.addExchangeRateWalletModal {
    margin-top: 8%;
    .modal-wallet-info {
        line-height: 24px;
    }
    .btn-wrap {
        Button {
            width: 100%;
            // margin: 0 5%;
            &:nth-child(1) {
                border: 1px solid #cccccc;
                color: #ffffff;
                background: #cccccc;
            }
            &:nth-child(2) {
                background-color: #cccccc;
                color: #fff;
                &:hover {
                    border: 1px solid #cccccc;
                }
            }
        }
        .active {
            border: 1px solid #00a6ff;
            background-color: #00a6ff !important;
            color: #ffffff !important;
        }
    }
    .WalletModal-form-wrap {
        .errorTip {
            width: 100%;
            line-height: 20px;
            color: #f92d2d;
            font-size: 12px;
        }
        .ant-form-item-label > label::after {
            content: "*";
            color: #f00;
        }
    }
}

.showInfoWalletModal {
    width: 150px !important;
    .ant-modal-body {
        padding: 0 !important;
        background-color: #000000cc;
        border-radius: 6px;
        .ant-modal-confirm .ant-modal-body {
            padding: 0 !important;
        }
        .ant-modal-confirm-body .ant-modal-confirm-content {
            padding: 0 !important;
            margin-top: 25px;
            color: #fff;
        }
    }

    .ant-btn-primary,
    .ant-modal-close-x {
        display: none;
    }
}

.redTip {
    color: #f92d2d;
}

.fontBold {
    font-weight: bold;
}

.USDT-promotion-wrap {
    padding-bottom: 15px;
    position: relative;
    cursor: pointer;
    .anticon.anticon-close {
        position: absolute;
        top: 8px;
        right: 14px;
        color: white;
    }
}

// 新增USDT汇率变更提示Modal Style
.usdt-exchange-hint {
    .title {
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 1.6em;
    }
    .content {
        text-align: left;
    }
    .desc {
        margin-bottom: 1em;
    }
    .ant-radio {
        vertical-align: text-bottom;
    }
    .ant-modal-confirm-btns .ant-btn:first-child {
        font-weight: bold;
        color: @theme;
    }
}

// 新增充值用户完善银行卡号OTP验证
.verify-code-wrap {
    .input-otp-wrap {
        display: flex;
        transform: scale(1);
        position: relative;
        justify-content: space-between;
        input.ant-input {
            width: 38px;
            height: 44px;
            line-height: 60px;
            font-size: 16px;
            text-align: center;
        }
    }
}
.banknumber-verify-otp {
    h3 {
        font-weight: bold;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .inline-link-btn {
        padding: 10px 0;
    }
    .retry-send.ant-btn-background-ghost.ant-btn-link {
        margin: 10px 0;
        color: #999;
        & > span {
            color: #42d200;
        }
    }
}
.confirm-clear-mar .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0;
}
.bankinfo-verify-otp {
    h3 {
        font-weight: bold;
        margin-top: 10px;
    }
}
.loading-animation {
    animation-name: loadingRotate;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes loadingRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loadingRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
// 充值成功结果modal.info
.deposit-result {
    text-align: left;
    color: #222222;
    margin-bottom: 0;
    dd {
        margin-bottom: 0;
    }
    dt,
    dd {
        span {
            color: #f92d2d;
            font-weight: normal;
        }
    }
}
//泰达币提款第二笔错误提示 modal.info
.ant-modal.modal-Info {
    .ant-modal-body {
        .ant-modal-confirm-content {
            margin-top: 0;
            padding-bottom: 0;
            .content {
                margin: 20px 0;
                p {
                    margin-bottom: 0.5em;
                    &:last-child {
                        width: 78%;
                        margin: auto;
                    }
                }
            }
        }
    }
}

.div-cannot-use,
.MainBlance {
    background-color: white;
    border-radius: 5px;
}
.div-cannot-use {
    height: 374px;
    position: relative;
    .div-cannot-use-inner {
        position: absolute;
        text-align:center;
        width: 210px;
        margin: 0 0 0 -88px;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);
        img {
            display:block;
            margin:auto;
        }
    }
}
.MainBlance {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2% 5%;
    margin-bottom: 5%;
    .item-label {
        margin: auto;
        img {
            padding-left: 5px;
        }
    }
    .item-blance {
        display: grid;
    }
}

.successPaper {
    background-color: white;
    padding: 10px 30px;
}

.radioCon {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .radio__btn {
        label {
            font-size: 11px;
            display: flex;
            align-items: center;
        }
        input[type="radio"] {
            display: none;
        }
        label:before {
            content: " ";
            display: inline-block;
            position: relative;
            margin: 0 5px 0 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: 1px solid #cccccc;
            background-color: transparent;
        }
        input[type="radio"]:checked + label:before {
            border-radius: 50%;
            width: 13px;
            height: 13px;
            content: "";
            display: block;
            background: #00a5fd;
            border: 3px solid white;
            outline: #00a5fd solid 1px;
            outline-offset: -1px;
        }
        background-color: white;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 2%;
        width: 48%;
        height: 100%;
    }
}

.ant-divider {
    background-color: rgb(204, 204, 204) !important;
}

.transfer-form-wrap {
    .ant-btn-primary[disabled] {
        background-color: #cccccc;
        color: #ffffff;
    }
    .drop-area-promot {
        .ant-select-selection-selected-value {
            width: 280px;
            > div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.DepositInfo {
    .list {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border-top: 1px solid #0000000a;
        align-items: center;
    }
}

.WithdrawalVerification {
    padding: 30px;
    .ant-steps-item-wait .ant-steps-item-icon {
        background-color: #fff;
        border-color: #cccccc;

        .ant-steps-icon {
            color: #cccccc;
        }
    }
    .IDCardVerify {
        padding: 25px 0;
    }
    .VerificationSteps {
        padding: 10px 40px;
    }
    .form-item {
        label {
            display: block;
            padding-bottom: 10px;
        }
    }
    .note {
        color: #999999;
    }

    .AddressVerify {
        padding-bottom: 30px;
    }
}

.BalanceList {
    .Content {
        display: flex;
        flex-flow: wrap;
        .balance-box {
            flex: 0 0 50%;
            padding-bottom: 15px;
            .num {
                font: normal normal bold 14px/17px Microsoft YaHei;
                color: #222222;
            }


        }
        .UnderMaintenance {
            .localizedName,
            .num {
                filter: grayscale(100%);
                opacity: 0.4;
            }
        }
    }
}

.BankList {
    .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
        text-align: left;
    }
}
.wallet-deposit {
    .ant-modal-header {
        .ant-modal-title {
            color: white;
        }
    }

    .ant-modal-body {
        font-family: "Microsoft YaHei";
        letter-spacing: 1px;
        line-height: 1.5rem;
        padding: 20px 30px;

        .modal-prompt-info {
            background-color: transparent;
            color: #999999;
            line-height: 1.5rem;
            padding: 0;
            margin: 0;
        }

        .userNames-input {
            &.havename {
                .ant-input-disabled {
                    background-color: transparent;
                    color: #000;
                }

                .ant-input-group-addon {
                    background-color: transparent;
                    color: #000;
                    font-weight: bold;
                }
            }
        }

        .ant-form-item {
            margin-bottom: 20px;

            &.wallet-deposit_phoneEdit {
                margin-bottom: 10px;

                .ant-form-item-label > label {
                    margin-bottom: 4px;
                    font-size: 12px;
                    font-weight: normal;
                }
            }

            .ant-form-explain {
                font-size: 12px;
                margin-bottom: 0;
            }
        }

        .wallet-label-phoneNum {
            margin-bottom: 0;
        }

        .wallet-label-otp {
            margin-bottom: 10px;

            .ant-form-item-label {
                font-weight: normal;
                margin-bottom: 4px;

                label {
                    font-size: 12px;
                }
            }

            .ant-input-group-addon {
                padding: 0 16px;
            }
        }

        .ant-form-item-required {
            font-size: 12px;
            font-weight: normal;
        }

        .realName {
            label {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .ant-form-item-label {
            font-weight: bold;
            padding: 0;
            margin-bottom: 10px;
        }

        .otp-cs-tip {
            font-size: 12px;
            margin: 0 0 15px 0;
            line-height: 1rem;

            button {
                font-size: 12px;
            }

            &.last-cs-tip {
                margin-bottom: 20px;
            }
        }

        .input-label {
            font-size: 12px;
            line-height: 1rem;
            margin-bottom: 4px;
        }
        .has-error .disabled-time {
            .ant-input {
                border-right: 0;
            }
            .ant-input-group-addon {
                border-color: #ff4d4f;
                border-left: 0;
            }
        }
        .forWalletDeposit {
            .ant-input-group-addon {
                background-color: #35c95b;
                border-color: #35c95b;
            }
        }
        .has-error .forWalletDeposit {
            .ant-input {
                border-right: 0;
            }
            .ant-input-group-addon {
                border-color: #ff4d4f;
                border-left: 0;
            }
        }

        .btn-wrap {
            .ant-btn {
                letter-spacing: -2px;
            }
        }

        .remain {
            font-size: 12px;
            color: #999;
            margin-bottom: 10px;

            span {
                color: #00a5fd;
            }
        }

        .skip {
            text-align: center;
            font-size: 12px;
            color: #00a5fd;
        }
    }
}

.exchange-hint {
    width: 284px;
    margin: 0 auto;
}

.OrderContent {
    background-color: #f0f0f2;
    color: #000;
    text-align: left;
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
}
.currentWithdraw-lb {
    .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
        text-align: left;
    }
}
.depositInfo{
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 15px;
}
.UploadFileData {
    text-align: left;
    background-color: white;
    margin-top: 15px;
    padding: 10px;
    z-index: 1009;
    position: relative;
    .note {
        background: #ffe273;
        border-radius: 4px;
        color: #323232 !important;
        padding: 5px 10px;
        font-size: 12px;
    }
    button {
        background: #f0f0f2 0% 0% no-repeat padding-box;
        border-radius: 10px;
        color: #999999;
        border: 0;
        margin: 10px 0;
        height: 40px;
    }
}

.PickerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-calendar-ym-select {
    a {
        color: #1c8eff !important;
    }
}

.Usdt-Exchange {
    .DepositMoeny {
        background: #f5f5f5;
        border-radius: 10px;
        padding: 10px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        color: #222222;
    }
    .DepositMoenyList {
        background: #f5f5f5;
        border-radius: 10px;
        text-align: left;
        padding: 15px;
        li {
            margin-bottom: 5px;
        }
        small {
            color: #999999;
        }
    }
    .ChangeList {
        text-align: left;
        li {
            margin-bottom: 10px;
        }
    }
}

.UnfinishedGamePopUp {
    width: 359px;
    .Content {
        padding: 40px 20px 20px;
    }
    .GamesContent {
        text-align: center;
        .Modal-text {
            color: #000;
            padding: 10px;
        }
        .GameMiniLogo {
            background-color: #efeff4;
            border-radius: 8px;
            padding: 20px;
            margin-top: 20px;
            .openGame {
                flex: 0 0 50%;
            }
            .list {
                margin-bottom: 10px;
            }
        }
        .GameOpen {
            flex: 0 0 45%;
        }
    }
}

.Modal_file_btn {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
}
.smallCountDownModal {
    .ant-modal-body {
        padding: 0 16px 16px;
    }
}
.depositSuccess {
    .ant-steps-item-tail {
        top: 20px !important;
        left: 8px !important;
        padding: 0px 0 8px !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        margin-left: 2px !important;
        margin-top: 2px !important;
    }
    .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #bfbfbf !important;
    }
}


.wrapAddWithdrawalWallet {
    .ant-select-dropdown-menu-item {
        padding:8px 16px;
        position:relative;
        br {
            display: none
        }
        span {
            display: block;
            line-height:1.5;
        }
        .anticon-check {
            position:absolute;
            right:16px;
            top:16px;
        }
    }
    .ant-select-dropdown-menu-item-selected {
        font-weight:normal
    }
}
