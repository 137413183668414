.promotion-tabs {
    .ant-radio-group {
        display: flex;
    }
}

.promotion-deposit-wrap {
    padding: 70px 10px;
    padding-top: 40px;
}

.promotion-deposit-wrap > .ant-col:first-child {
    text-align: center;
    margin-top: 40px;
}

.promotion-deposit-wrap .slider-title {
    margin-top: 16px;
    margin-bottom: 20px;
}

.promotion-deposit-wrap .ant-input-number {
    width: 100%;
}

.promotion-deposit-wrap .ant-input-number-handler-wrap {
    display: none;
}

.promotion-deposit-wrap .ant-select {
    display: block;
}

.promotion-modal.title-background.Form-modal input.ant-input-disabled {
    background: #fff;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    height: 43px;
}

.promotion-modal.title-background.Form-modal input.ant-input-number-input {
    border-radius: 20px;
    text-align: right;
    font-weight: bold;
    color: @theme;
    font-size: 20px;
}

.promotion-modal .ant-slider-handle {
    background-color: @theme;
}

.promotion-transfer-modal._2 .ant-modal-body {
    height: 576px;
}

.promotion-transfer-modal.promotion-modal .ps_bottom {
    position: relative;
}

.step-line-row {
    margin-left: 10px;
    margin-right: 20px;
}

.step-line-row .ant-col {
    height: 3px;
    border-left: 1px solid #bfbfbf;
}

.step-line-row .ant-col:last-child {
    border-right: 1px solid #bfbfbf;
}

.step-percentage-row .ant-col:nth-child(1) {
    text-align: left;
}

.step-percentage-row .ant-col:nth-child(2) {
    text-align: center;
}

.step-percentage-row .ant-col:nth-child(3) {
    text-align: right;
}

.promotion-search.ant-input-group-wrapper {
    width: 180px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    .ant-input {
        border-left: 2px solid #f92d2d;
        border-bottom: 2px solid #f92d2d;
        border-top: 2px solid #f92d2d;
        border-right: 0;
        box-shadow: none;
        padding-left: 38px;
        background-image: url(/cn/img/promotions/search.png);
        background-repeat: no-repeat;
        background-position: 10px center;
    }
}

.tlc-promotions-wrap {
    margin-top: -55px !important;
}

.tlc-promotions-wrap .ant-tabs-bar.ant-tabs-left-bar {
    margin-top: 60px;
}

.tlc-promotions-wrap.ant-tabs {
    overflow: visible;
    padding-bottom: 30px;
}

.tlc-promotions-wrap.ant-tabs .ant-tabs-left-content {
    border-left: 0;
}

.transfer-money-wrap {
    position: relative;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    padding: 20px;
}

.transfer-to-target-btn {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 26px;
    height: 26px;
    background-image: url("/cn/img/promotions/sb_transfer.png");
    cursor: pointer;
    &.curr {
        background-image: url("/cn/img/promotions/sb_transfer_disable.png");
        cursor: default;
    }
}

// 优惠Confirm样式表
.pormotion-confirm {
    .svg {
        color: @theme;
        font-size: 66px;
        margin-bottom: 0;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-confirm-body .ant-modal-confirm-content {
        margin-top: 0;
    }
    &.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
        border-radius: 22.5px;
    }
}

.loading_card_list {
    display: flex;
    flex-flow: wrap;
    li {
        width: 33.333%;
        height: 230px;
        border-radius: 20px;
        margin-bottom: 20px;
        .box {
            border: 1px solid #ddd;
            height: 100%;
            margin: 10px;
            border-radius: 20px;
            overflow: hidden;
            &:extend(.animationcard);
        }
    }
}

.animationcard {
    background: -webkit-gradient( linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
}

@-webkit-keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.promotions-content {
    //上方Tab選單
    .ant-tabs-top-bar {
        margin-bottom: 30px;
        .ant-tabs-nav-wrap {
            display: unset;
        }
    }
    //優惠Tab && 返水左側選單
    .tlc-promotions-sidebar .ant-tabs-left-bar, .rebate-leftside-tab .ant-tabs-left-bar {
        width: 200px;
        background-color: white;
        text-align: center;
        .ant-tabs-nav-scroll {
            width: 100%;
        }
        //優惠Tab
        .ant-tabs-nav-wrap {
            display: unset;
        }
        //包裹items container
        .ant-tabs-nav > div {
            display: flex;
            flex-direction: column;
            //每個item
            .ant-tabs-tab {
                padding: 10px 0 10px 56px;
                font: normal normal 14px/19px Microsoft YaHei;
                .icon-list {
                    >:last-child {
                        max-width: 60px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            //被點擊的item
            .ant-tabs-tab-active {
                font: normal normal bold 14px/19px Microsoft YaHei;
                background-color: #e0e0e0;
                color: #000;
            }
        }
        //tab indicator
        .ant-tabs-ink-bar {
            width: 6px;
        }
    }
    .ant-tabs-left-bar {
        background-color: white;
        border-radius: 10px;
        padding: 15px 0 0 0;
    }
    .ant-tabs-ink-bar {
        left: 0 !important;
        // width: 4px !important;
    }
    //我的優惠Tab > 免費投注Tab
    .PromotionsFreeBet {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 30px;
        column-gap: 30px;
        row-gap: 32px;
        .FreeBet-item {
            width: 100%;
            height: 154px;
            border-radius: 8px;
            background: white;
            -webkit-mask-image: radial-gradient( circle at 15px 50%, transparent 15px, white 15.5px), radial-gradient( closest-side circle at 50%, white 99%, transparent 100%);
            -webkit-mask-size: 100%, 12px 4px;
            -webkit-mask-repeat: repeat, repeat-x;
            -webkit-mask-position: -15px, 50% 50%;
            -webkit-mask-composite: source-out;
            mask-composite: subtract;
            position: relative;
            .Content {
                display: flex;
                flex-direction: column;
                height: 100%;
                gap: 34px;
                padding: 24px 32px;
                .content {
                    display: flex;
                    flex-direction: column;
                    .Name {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        line-height: 20px;
                    }
                    .Time {
                        color: #999999;
                    }
                }
                .btn-apply-freebet {
                    width: 38.187%;
                }
            }
            .Freebet-popover {
                cursor: pointer;
            }
        }
    }
}

.Form-modal {
    .ant-modal-body {
        min-height: 360px !important;
    }
    .ant-input-group-addon, input {
        border: 0;
        border-radius: 0;
        background-color: #ffffff;
        box-shadow: 0px 2px 0px #0000000a;
    }
    textarea {
        border: 0;
        height: 100% !important;
    }
}

//General Modal Style
.promotion-modal.promotionDailyGift-modal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 0 30px 30px;
        }
    }
}
.promotion-modal {
    .ant-modal-content {
        .ant-modal-header {
            .ant-modal-title {
                text-align: center;
                color: #ffffff;
                font-weight: 700;
            }
        }
        .ant-modal-body {
            padding: 30px;
            display: flex;
            flex-direction: column;
            .ant-spin-container {
                padding: 0;
            }
            // 優惠投注方框
            .promotion-info-box {
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                padding: 20px 0;
                margin-bottom: 16px;
                h3 {
                    text-align: center;
                    font: normal normal bold 14px/19px Microsoft YaHei;
                    margin-bottom: 20px;
                }
                .amount-status-box {
                    display: flex;
                    // 各金額方框
                    div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .amount {
                            font: normal normal bold 14px/19px Microsoft YaHei;
                            color: #222222;
                        }
                    }
                }
            }
            textarea {
                resize: none;
                &::-webkit-resizer {
                    display: none;
                }
            }
        }
        .ant-modal-footer {
            padding: 0 30px 30px 30px;
            .ant-btn-primary {
                margin: 0;
                width: 100%;
                font: normal normal bold 14px/19px Microsoft YaHei;
            }
        }
    }
}

//客製餘額不足modal{
.deposit-warning-modal {
    .ant-modal-footer {
        > div {
            display: flex;
            width: 100%;
            gap: 20px;
            button {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                padding: 10px 0;
                cursor: pointer;
                &:nth-child(1) {
                    background-color: transparent;
                    border: 1px solid #00a6ff;
                    letter-spacing: 0px;
                    color: #00a6ff;
                }
                &:nth-child(2) {
                    background: #00a6ff 0% 0% no-repeat padding-box;
                    color: #fff;
                    border: 1px solid #fff;
                }
                span {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}

//客製優惠詳情modal
.promotion-detail-modal {
    width: 1120px;
    .ant-modal-header {
        padding: 0px;
        background-color: #00a6ff;
        .ant-modal-title {
            font: normal normal bold 18px/24px Microsoft YaHei;
            padding: 18px 0;
            color: #fff;
            text-align: center;
        }
    }
    .ant-modal-body {
        padding: 30px 50px;
        .ant-spin-container {
            padding: 0;
            .apply-promotion-detail-box {
                margin-bottom: 30px;
                border: none;
                padding-bottom: 30px;
            }
        }
    }
    .button-box {
        display: flex;
        justify-content: center;
        .rate-viewer-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            .rate-base {
                height: 10px;
                width: 600px;
                background-color: #eae7e7;
                border-radius: 50px;
                margin-bottom: 12px;
                .percentage {
                    height: 100%;
                    background-color: #00a6ff;
                    position: relative;
                    border-radius: 50px;
                    .indicator {
                        position: absolute;
                        right: 0%;
                        top: 0%;
                        width: 22px;
                        height: 22px;
                        border-radius: 50px;
                        background-color: #21acf7;
                        border: 2px solid #ffffffe6;
                        transform: translate(50%, -25%);
                        margin-bottom: 22px;
                    }
                }
            }
            .scale-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 25px;
                // 每個刻度
                .scale-text-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .scale-line {
                        position: relative;
                        width: 1px;
                        height: 5px;
                        background-color: #a1a1a1;
                        margin-bottom: 10px;
                        p {
                            position: absolute;
                            top: 0%;
                            margin: 0;
                            transform: translate(-25%, 50%);
                            color: #00a6ff;
                        }
                    }
                }
            }
            p {
                font: normal normal normal 12px/16px Microsoft YaHei;
                letter-spacing: 0px;
                color: #222222;
            }
        }
        button {
            width: 30.8%;
            border: none;
            font: normal normal bold 14px/19px Microsoft YaHei;
            color: #fff;
            background-color: #00a6ff;
            padding: 10px 0;
            border-radius: 10px;
            cursor: pointer;
        }
        button:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
        }
        .btn-get-bonus {
            background-color: #0ccc3c;
        }
    }
}

//客製轉帳Modal
.promotion-transfer-modal {
    .ant-modal-body {
        padding: 30px;
        .promotion-deposit-wrap {
            padding: 0;
            > div:first-child {
                margin-top: 0;
                text-align: left;
            }
            .ant-input-number-input-wrap {
                input {
                    text-align: left;
                    font: normal normal normal 16px/22px PingFang SC;
                    color: #222222;
                }
            }
        }
        .ant-spin-container {
            padding: 0;
            .transfer-function-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                > div {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    input {
                        background: #fff 0% 0% no-repeat padding-box;
                        border: 1px solid #cccccc;
                        border-radius: 8px;
                        padding: 23px 15px;
                        display: block;
                        cursor: auto;
                    }
                    .input-transfer-to {
                        background: #f4f2f2 0% 0% no-repeat padding-box;
                        cursor: default;
                        color: #999999;
                    }
                    .input-amount {
                        padding: 10px 15px;
                    }
                }
                .from-wallet-select-box {
                    position: relative;
                    .button-transfer {
                        display: block;
                        cursor: pointer;
                        background-color: transparent;
                        border: none;
                        position: absolute;
                        top: 49%;
                        right: 10%;
                        z-index: 100;
                    }
                    .ant-select {
                        .ant-select-selection-selected-value {
                            width: 100%;
                            .option-item {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                p {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                .warm-reminder {
                    text-align: left;
                    background: #fff5bf 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    font: normal normal normal 12px/20px Microsoft YaHei;
                    letter-spacing: 0px;
                    color: #83630b;
                    padding: 10px 15px;
                }
                .account-box, .amount-box {
                    width: 100%;
                }
                .transfer-money-wrap {
                    border: none;
                    border-radius: none;
                    padding: 0px;
                }
                .promotion-info-box {
                    margin: 0 0 20px 0;
                    padding: 20px 30px;
                    text-align: center;
                    width: 100%;
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    gap: 15px;
                    .promotion-title {
                        font: normal normal bold 14px/17px Microsoft YaHei;
                        letter-spacing: 0px;
                        color: #222222;
                    }
                    .amount-box {
                        display: flex;
                        justify-content: space-between;
                        .amount-item {
                            .title {
                                text-align: center;
                                letter-spacing: 0px;
                                color: #999999;
                            }
                            .amount {
                                font: normal normal bold 14px/17px Microsoft YaHei;
                                letter-spacing: 0px;
                                color: #222222;
                            }
                        }
                    }
                }
            }
            .ps_button {
                display: flex;
                flex-direction: column;
                gap: 15px;
                button {
                    width: 100%;
                    padding: 10px 0;
                    border-radius: 10px;
                    border: none;
                    background-color: #00a6ff;
                    border: 1px solid #fff;
                    color: #fff;
                    cursor: pointer;
                }
                .btn-submit-transfer:disabled {
                    color: #fff;
                    background-color: #cccccc;
                }
                .btn-go-deposit {
                    background-color: #fff;
                    border: 1px solid #00a6ff;
                    color: #00a6ff;
                }
            }
        }
    }
}

// 客製Bonus申請成功(轉帳成功)Modal
.bonus-apply-success-modal {
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 60px;
            height: 60px;
            margin-bottom: 16px;
            margin-top: 20px;
        }
        h1 {
            font: normal normal 600 18px/24px PingFang SC;
            margin-bottom: 16px;
            letter-spacing: 0;
        }
        span {
            font: normal normal normal 14px/24px PingFang SC;
            color: #999999;
        }
    }
    .ant-modal-footer {
        margin-top: 5px;
        button {
            height: 40px;
        }
    }
}

// 客製已提交資料查看Modal
.check-apply-data-container {
    .ant-modal-body {
        h3 {
            font: normal normal 600 14px/18px Microsoft YaHei;
            color: #222222;
            margin-bottom: 32px;
        }
        > div {
            display: flex;
            flex-direction: column;
            gap: 15px;
            //每組label+input
            .ant-input-group-wrapper {
                & + p {
                    padding: 3px 0 0 11px;
                    margin: 0;
                    font-size: 12px;
                    span {
                        color: #00a6ff;
                        cursor: pointer;
                    }
                }
                // 手動作成label
                label {
                    display: block;
                    padding-left: 11px;
                    margin-bottom: 5px;
                    color: #222222;
                }
                textarea {
                    max-height: 80px;
                    padding: 17px 20px;
                    color: #222222;
                    background-color: #fff;
                }
                .ant-input-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    //自動生成label created-label
                    .ant-input-group-addon {
                        background-color: transparent;
                        border: none;
                        color: #222222;
                    }
                    input {
                        display: block;
                        background: #f4f2f2 0% 0% no-repeat padding-box;
                        border: 1px solid #cccccc;
                        border-radius: 8px;
                        color: #666666;
                        padding: 20px 13px;
                    }
                }
            }
        }
    }
}

// 客製Manual申請Modal(查看運送資料)
.manual-apply-modal {
    .Formbox {
        .user-info-box {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .ant-input-group-wrapper {
                color: #999999;
                .ant-input-wrapper {
                    gap: 10px;
                    span {
                        padding: 0;
                        color: #222222;
                        font-weight: 500;
                    }
                    input {
                        border-radius: 8px;
                        padding: 20px 13px;
                    }
                    .ant-input[disabled] {
                        background-color: #f4f2f2;
                    }
                }
                & + p {
                    margin-top: 5px;
                    span {
                        color: #00a6ff;
                        cursor: pointer;
                    }
                }
            }
            // 第一組input :用戶名
            > .ant-input-group-wrapper {
                input {
                    color: #222222;
                }
            }
            div input {
                color: #999999;
            }
        }
        .remarks-box {
            display: flex;
            flex-direction: column;
            textarea {
                padding: 17px 20px;
                &:focus-visible {
                    outline: none;
                }
            }
        }
    }
}

// 客製Manual申請結果Modal
.manual-result-modal {
    height: 500px;
    display: flex;
    align-items: center;
    .ant-modal-content {
        width: 100%;
        .ant-modal-body {
            padding: 20px 30px 30px 30px;
            .result-content-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    margin-bottom: 30px;
                }
                p {
                    margin: 0;
                }
                .text-status {
                    font: normal normal 600 16px/24px PingFang SC;
                    letter-spacing: 0px;
                    color: #222222;
                }
                .text-desription {
                    font: normal normal normal 14px/24px PingFang SC;
                    letter-spacing: 0px;
                    color: #222222;
                    margin-bottom: 10px;
                }
                .button-box {
                    display: flex;
                    width: 100%;
                    gap: 25px;
                    button {
                        width: 100%;
                        white-space: nowrap;
                        padding: 10px 0;
                        border: 1px solid #26a9e1;
                        border-radius: 8px;
                        cursor: pointer;
                        &:nth-child(1) {
                            background-color: #fff;
                            color: #26a9e1;
                        }
                        &:nth-child(2) {
                            background-color: #00a6ff;
                            color: #fff;
                            border: 1px solid #fff;
                        }
                    }
                }
            }
        }
    }
}

// 客製Free Promotion溫馨提醒Modal
.warm-reminder-modal {
    span {
        margin-bottom: 40px;
    }
    .ant-modal-footer {
        > div {
            display: flex;
            width: 100%;
            gap: 20px;
            button {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                padding: 10px 0;
                cursor: pointer;
                &:nth-child(1) {
                    background-color: transparent;
                    border: 1px solid #00a6ff;
                    letter-spacing: 0px;
                    color: #00a6ff;
                }
                &:nth-child(2) {
                    background: #00a6ff 0% 0% no-repeat padding-box;
                    color: #fff;
                    border: 1px solid #fff;
                }
                span {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}

// 客製返水自訂義日期Modal
.rebate-time-picker-modal {
    .ant-modal-content {
        > button {
            margin-top: 10px;
            svg {
                color: black;
                // padding: 10px 0;
            }
        }
        .ant-modal-header {
            background-color: transparent;
            .ant-modal-title {
                color: #222;
                padding: 10px 0;
            }
        }
    }
}

.icon-list {
    display: flex;
    // margin-left: 10%;
    .icon-image {
        filter: grayscale(1);
        padding-right: 10px;
        height: 20px;
        vertical-align: -0.4em;
    }
    .icon-image-active {
        filter: unset;
    }
}

.Formbox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    h3 {
        font: normal normal 600 14px/18px Microsoft YaHei;
        letter-spacing: 0px;
        color: #222222;
        text-align: left;
    }
    .ant-input-group {
        display: flex;
        flex-direction: column;
        input {
            border: 1px solid #cccccc;
        }
    }
    textarea {
        border: 1px solid #cccccc;
        border-radius: 8px;
        margin-bottom: 25px;
        margin-top: 10px;
    }
    & + .ps_bottom button {
        width: 100% !important;
        &:disabled {
            background-color: #cccccc !important;
            color: #fff !important;
            border: none !important;
            cursor: not-allowed;
        }
    }
}

.htmlbox {
    padding-bottom: 100px;
}

.ps_bottom {
    text-align: center;
    margin: 0 auto;
    button.button {
        width: 345px;
        height: 40px;
        background: #00a6ff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        border: 0;
        color: white;
        cursor: pointer;
    }
    button.button[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border: 1px solid #bfbfbf;
        text-shadow: none;
        box-shadow: none;
    }
    p {
        color: #1c8eff;
        padding: 15px;
        padding-bottom: 10px;
        font-size: 12px;
        cursor: pointer;
    }
}

//優惠篩選右側結果區塊
.promotion-list-box {
    .Pms_data_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        column-gap: 25px;
        row-gap: 30px;
        margin-bottom: 30px;
        min-height: 629px;
        position: relative;
        .item-list {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 2px 4px #00000014;
        }
        .promotion-item-box {
            width: 100%;
            height: 100%;
            cursor: pointer;
            //border-radius: 20px;
            overflow: hidden;
            //box-shadow: 0px 2px 4px #00000014;
            .content-box {
                height: 100%;
                display: grid;
                grid-template-rows: auto 1fr;
                .promotion-image {
                    width: 100%;
                    height: fit-content;
                    aspect-ratio: 5.714/2;
                    background-color: #ddd;
                }
                .text-box {
                    background-color: white;
                    padding: 10px 8px 10px 10px;
                    cursor: pointer;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    .title {
                        font: normal normal bold 16px/21px Microsoft YaHei;
                        margin: 0 0 8px 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* 指定显示的行数 */
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .period {
                        display: flex;
                        align-items: center;
                        font: normal normal normal 12px/16px Microsoft YaHei;
                        color: #999999;
                        p {
                            margin: 0;
                            letter-spacing: -0.3px;
                        }
                        span {
                            margin-right: 5px;
                            width: 14px;
                            height: 18px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        //當該分類內無優惠時的Empty style setting
        .ant-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 85%;
            .ant-empty-image {
                width: 13.637%;
                height: max-content;
                margin: 0;
            }
            .ant-empty-description {
                white-space: nowrap;
                font: normal normal normal 18px/24px Microsoft YaHei;
                letter-spacing: 0px;
                color: #999999;
            }
        }
    }
}

.promotions-banner {
    height: 160px;
    text-align: center;
    max-width: 1120px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 0 -35px;
    }
}

.promotions-content {
    padding-top: 20px;
    min-height: 900px;
}


/*2023.01.17 add*/

.promotionCard {
    overflow: auto;
    .Card {
        height: 160px;
        width: 327px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
        overflow: hidden;
        img {
            width: 100%;
            height: 114px;
            border-radius: 10px 10px 0px 0px;
        }
    }
}

.blue {
    color: #00a6ff;
}


/*Rebate*/

.total_reabate {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 3% 0;
    span {
        font-size: large;
        padding: 0 5%;
    }
}

//返水內容
.promotion-modal .ant-spin-container {
    padding: 3% 5%;
}

.content_list {
    background-color: #e0e0e0;
    border-radius: 20px;
    h3 {
        padding-left: 5%;
    }
}

.my-promotion-container {
    .big-empty-box {
        p {
            font-size: 18px;
        }
    }
}

.rebate-container {
    .filter-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    // 返水清單 container
    .Pms_data_rebate {
        .defined-date-description {
            width: 100%;
            text-align: right;
            margin-top: 15px;
            span {
                margin-left: 10px;
                color: #1c8eff;
                cursor: pointer;
            }
        }
        .rebate-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(5, 1fr);
            column-gap: 30px;
            position: relative;
            margin-bottom: 30px;
            min-height: 629px;
            position: relative;
            .rebate-item-box {
                background-color: #ffffff;
                border: 1px solid #e0e0e0;
                border-radius: 10px;
                padding: 15px 20px;
                margin-bottom: 10px;
                .header-box {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #e0e0e0;
                    .profile {
                        display: flex;
                        .icon-image {
                            width: 48px;
                            height: 48px;
                        }
                        .text-box {
                            display: flex;
                            gap: 12px;
                            h3 {
                                color: #222222;
                                font-weight: 700;
                                margin: 0;
                            }
                            p {
                                margin: 0;
                            }
                        }
                    }
                    button {
                        cursor: pointer;
                        background-color: transparent;
                        border: none;
                    }
                }
                .rebate-info-box {
                    padding-top: 15px;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    text-align: center;
                    .content_item {
                        p {
                            color: #222222;
                        }
                        .title {
                            color: #999999;
                        }
                        .total-amount {
                            color: #f92d2d;
                        }
                    }
                }
            }
            //當該分類內無優惠時的Empty style setting
            .ant-empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                position: absolute;
                width: 100%;
                height: 70%;
                .ant-empty-image {
                    width: 160px;
                    height: max-content;
                    margin: 0;
                    img {
                        width: 100%;
                    }
                }
                .ant-empty-description {
                    white-space: nowrap;
                    font: normal normal normal 18px/24px Microsoft YaHei;
                    letter-spacing: 0px;
                    color: #999999;
                }
            }
        }
    }
}

// 返水日期選擇器 Rebate Time Picker
.rebate-time-picker-container {
    .ant-calendar-input-wrap {
        display: none;
    }
    .ant-calendar-date-panel {
        .ant-calendar-ym-select {
            a {
                color: black;
            }
        }
    }
}

// 返水詳細清單(Table)
.table-rebate-detail {
    // Table Style
    .ant-table-content {
        .ant-table-thead {
            tr {
                th {
                    color: black;
                    text-align: center;
                    background-color: transparent;
                }
            }
        }
        .ant-table-tbody {
            tr {
                text-align: center;
            }
        }
        .ant-table-placeholder {
            .ant-empty-description {
                color: #c7c7c7;
            }
        }
    }
    // Pagination Style
    .ant-pagination {
        width: 100% !important;
        margin: 30px 0 0 0 !important;
        li {
            a {
                color: #222222;
            }
        }
        // Current page style
        .ant-pagination-item-active {
            border-color: transparent;
            background-color: #f0f0f2;
        }
    }
}

// 轉帳錢包選擇
.wallet-list-box {
    .option-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        p {
            margin: 0;
        }
        .button-transfer {
            background-color: transparent;
            border: none;
            display: none;
        }
    }
}
