#maintain.common-distance-wrap {
    margin: 0 auto;
    min-width: 1200px;
    min-height: 100vh;
    // background-image: url('/cn/img/maintain/y-so-serious-white.png');
    //background-image: url('/cn/img/closeIcon/Bg@2x.png');
    background-color: #efeff4;
    overflow: hidden;
    &.black {
        background-image: url("/cn/img/maintain/y-so-serious.png");
    }
    .maintain-header-wrap {
        width: 100%;
        height: 120px;
        min-width: 1200px;
        background-color: #00a6ff;
    }
    .maintain-header-wrap .tlc-sprite {
        display: inline-block;
        width: 32px;
        height: 32px;
    }
    .maintain-header-wrap .tlc-sprite.xijia {
        background-position: -48px -81px;
    }
    .maintain-header-wrap .tlc-sprite.ehome {
        background-position: -87px -79px;
    }
    .maintain-header-wrap .common-distance > div.inline-block {
        width: 50%;
        color: #fff;
        line-height: 1.3;
    }
    .maintain-header-wrap .common-distance > div.inline-block:last-child {
        text-align: right;
    }
    .maintain-header-wrap .common-distance > div.inline-block .tlc-partner-section {
        text-align: left;
    }
    .maintain-header-wrap .common-distance .logo-wrap img {
        margin-top: 10px;
        height: 100px;
    }
    .maintain-header-wrap .common-distance .sponsor-wrap {
        vertical-align: -2.4em;
    }
    .common-distance {
        width: 1200px;
        padding: 0 40px;
        margin: 0 auto;
        position: relative;
    }
    .tlc-sprite {
        background-image: url("/cn/img/home/sprite20220315.png");
        background-repeat: no-repeat;
    }
    .center {
        text-align: center;
    }
    .inline-block {
        display: inline-block;
    }
    .maintain-content-wrap .tlc-sprite {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: -0.26em;
    }
    .maintain-content-wrap .tlc-sprite.maintain-email {
        background-position: -247px -134px;
    }
    .maintain-content-wrap .tlc-sprite.maintain-service {
        background-position: -293px -133px;
    }
    .maintain-content-wrap .tlc-sprite.maintain-phone {
        background-position: -269px -133px;
    }
    .maintain-content {
        padding: 50px 70px;
        height: 800px;
        width: 1100px;
        // background-image: url('/cn/img/maintain/orange.png');
        background-image: url("/cn/img/closeIcon/Mask_no.jpg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 50px;
        .maintain-img {
            vertical-align: top;
            margin-top: 10px; // 42
        }
        &.black {
            background-image: url("/cn/img/maintain/black.png");
        }
        .tlc-date .ant-calendar-picker-icon {
            background-image: none;
        }
    }
    .maintain-content > div {
        width: 50%;
        vertical-align: middle;
    }
    .maintain-info {
        box-shadow: 0 0 5px #ccc;
        background-color: #fff;
        border-radius: 8px;
        padding-top: 50px;
        padding-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
        width: 40% !important;
        margin-left: 5%;
    }
    .maintain-info h2 {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin-top: 2rem;
    }
    .maintain-info img {
        margin: auto;
        display: flex;
    }
    .maintain-info p {
        padding-bottom: 30px; // 60
        // padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        &.description {
            padding-left: 0px;
            padding-right: 0px;
            font-size: 16px;
            &.verify-des {
                padding: 0 0 0.5rem 0;
            }
        }
    }
    .maintain-info button {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        text-align: center;
        transition: 0.2s all;
        margin-top: 10px;
        outline: none;
        margin-bottom: 30px;
    }
    .maintain-info button.white-btn {
        border: 1px solid #666;
        background-color: #fff;
        margin-bottom: 0px;
    }
    .maintain-info button.theme-btn {
        border: 1px solid #00a6ff;
        background-color: #00a6ff;
        cursor: pointer;
        color: #fff;
    }
    .maintain-info button.verify-btn {
        border: 1px solid #1c8eff;
        background-color: #1c8eff;
        cursor: pointer;
        color: #fff;
        margin-bottom: 0px;
        &.isDisable {
            border-color: #fff;
            background-color: #00000033;
            &:hover {
                border-color: #fff;
                background-color: #00000033;
            }
        }
        &:hover {
            border-color: #1c66ff;
            background-color: #1c66ff;
        }
    }
    .maintain-info button.theme-btn:hover {
        border-color: #d21212;
        background-color: #d21212;
    }
    .reminder {
        text-align: center;
        margin: -1rem auto 0rem;
    }
    .red {
        color: #f92d2d;
    }
    .cs-blue {
        color: #1c8eff;
        cursor: pointer;
    }
}

.header-common-distance-wrap {
    .maintain-header-wrap {
        width: 100%;
        height: 120px;
        min-width: 1200px;
        background-color: #00a6ff;
    }
    .maintain-header-wrap .tlc-sprite {
        display: inline-block;
        width: 32px;
        height: 32px;
    }
    .maintain-header-wrap .tlc-sprite.xijia {
        background-position: -48px -81px;
    }
    .maintain-header-wrap .tlc-sprite.ehome {
        background-position: -87px -79px;
    }
    .maintain-header-wrap .common-distance > div.inline-block {
        width: 50%;
        color: #fff;
        line-height: 1.3;
    }
    .maintain-header-wrap .common-distance > div.inline-block:last-child {
        text-align: right;
    }
    .maintain-header-wrap .common-distance > div.inline-block .tlc-partner-section {
        text-align: left;
    }
    .maintain-header-wrap .common-distance .logo-wrap img {
        margin-top: 10px;
        height: 100px;
    }
    .maintain-header-wrap .common-distance .sponsor-wrap {
        vertical-align: -2.4em;
    }
}

// 重设密码 2021/1/7 新增
.reset-wrap {
    width: 430px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgb(202, 202, 202);
    margin: 60px auto;
    //padding: 20px;
    & > h3 {
        font-weight: bold;
        text-align: center;
        background-color: #00a5fd;
        padding: 15px;
        color: white;
        border-radius: 8px 8px 0 0;
    }
    .line-distance {
        height: 10px;
    }
    .ant-form-item-required::before {
        content: "";
    }
}

// 重置密码需求（自定义FormItem错误提示语，在字段合法的情况下出现错误提示）
.defined-error .ant-input, .defined-error .ant-input:hover {
    background-color: #fff;
    border-color: #ff4d4f;
}

.defined-error .ant-form-explain, .defined-error .ant-form-split, .defined-error .ant-form-extra {
    color: #ff4d4f;
}