// 针对消息中心UAT新增样式表
.account-wrap.message-wrap {
    .ant-tabs-nav {
        font-weight: 500;
        color: #888;
        .ant-tabs-tab-active {
            font-weight: bold;
            color: #000;
        }
    }

    .ant-select-selection-selected-value {
        width: 100%;
        text-align: center;
    }
    .ant-select-selection--single .ant-select-selection__rendered,
    .message-button > .ant-btn {
        font-size: 12px;
    }
}
.message-option {
    &.small-option {
        width: 82px;
        box-shadow: none;
        border: 1px solid #ccc;
        border-radius: 5px;

        .ant-select-dropdown-menu {
            padding: 0;
        }

        .ant-select-dropdown-menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 5px;
            height: 32px;
            font-size: 12px;
            text-align: center;

            &.ant-select-dropdown-menu-item-selected {
                background: none;
            }
        }
    }
}

.message-selection {
    .ant-select-selection {
        border-radius: 5px;
    }
}

.message-container {
    .ant-tabs-bar {
        &.ant-tabs-top-bar {
            margin-bottom: 20px;
        }
    }

    .border-tabs {
        .ant-tabs-nav-wrap {
            justify-content: flex-start;
        }

        .ant-tabs-ink-bar {
            height: 3px;
        }
    }

    .message-button {
        .ant-select {
            width: 82px;
        }

        .ant-btn {
            width: 112px;
            border-radius: 5px;
        }
    }
}

.markAllRead {
    &.ant-modal-confirm {
        .ant-modal-close {
            display: none;
        }

        .ant-modal-content {
            border-radius: 20px;

            .ant-modal-body {
                padding: 0;

                .ant-modal-confirm-title {
                    color: #ffffff;
                    background-color: #00a6ff;
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top-right-radius: 20px;
                    border-top-left-radius: 20px;
                }

                .ant-modal-confirm-content {
                    color: #222;
                }

                .ant-modal-confirm-btns {
                    margin: 30px;
                    .ant-btn {
                        color: #00a6ff;
                    }
                    .ant-btn-primary {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .ant-btn,
    .ant-btn.ant-btn-primary {
        letter-spacing: -2px;
    }
}

.message-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
        a {
            border: 1px solid #e0e0e0;
            color: #222222;
            border-radius: 5px;
        }

        &:hover,
        &:active,
        &:focus {
            a {
                border: 1px solid #e0e0e0;
                color: #222222;
                border-radius: 5px;
            }
        }
    }

    .ant-pagination-item {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        a {
            color: #222222;
        }

        &:hover,
        &:focus {
            border: 1px solid #f0f0f2;
            border-radius: 5px;
            background-color: #f0f0f2;
            a {
                color: #222222;
            }
        }
    }

    .ant-pagination-item-active {
        background-color: #f0f0f2;
        border: 1px solid #f0f0f2;
        border-radius: 5px;
        a {
            color: #222222;
        }
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
        .ant-pagination-item-container {
            .ant-pagination-item-link-icon {
                color: #e0e0e0;
            }
        }
    }
}
